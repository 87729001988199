import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

interface State {
  isOverviewVisible: boolean;
  setIsOverviewVisible: (data: boolean) => void;
}

const useState = createWithEqualityFn<State>(
  (set) => ({
    isOverviewVisible: false,
    setIsOverviewVisible: (data: State['isOverviewVisible']) =>
      set((state) => ({ ...state, isOverviewVisible: data })),
  }),
  shallow,
);

export const useOverviewVisibleStore = (): [
  State['isOverviewVisible'],
  State['setIsOverviewVisible'],
] => {
  return useState((state) => [
    state.isOverviewVisible,
    state.setIsOverviewVisible,
  ]);
};
