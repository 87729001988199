import { useRateLimitWarning } from '@mentimeter/react-hooks';
import React, {
  useCallback,
  type CSSProperties,
  useState,
  forwardRef,
} from 'react';
import {
  DragOverlay,
  DndContext,
  type DraggableSyntheticListeners,
  type DragStartEvent,
  type DragEndEvent,
  useSensors,
  useSensor,
  type DraggableAttributes,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { RenderIfVisible } from '../RenderIfVisible';
import { getIndexForMultiDrag, isDraggingMultiple } from './dnd-helpers';
import { OverviewDragOverlay } from './OverviewDragOverlay';
import type { OverviewListItemType } from './OverviewListItemThumbnail';

type RenderItem = (args: {
  item: OverviewListItemType;
  selected: boolean;
  active: boolean;
  index: number;
  isDragging: boolean;
  isDraggedElement: boolean;
  setNodeRef: (element: HTMLElement | null) => void;
  listeners: DraggableSyntheticListeners | undefined;
  style: React.CSSProperties;
  attributes: DraggableAttributes;
}) => React.ReactElement;

interface Props {
  items: OverviewListItemType[];
  selectedItemsIds: string[];
  activeItemId: string | undefined;
  isDragDisabled: boolean;
  renderItem: RenderItem;
  seriesBgColor: string;
  seriesTextColor: string;
  onDragEnd: (ids: string[], index: number) => void;
  onDisableKeyboardNavigation: (value: boolean) => void;
}

export const OverviewList = forwardRef<HTMLOListElement, Props>(
  (
    {
      items = [],
      selectedItemsIds,
      activeItemId,
      renderItem,
      onDragEnd: onDragEndFromProps,
      onDisableKeyboardNavigation,
      isDragDisabled = false,
      seriesBgColor,
      seriesTextColor,
    }: Props,
    forwardedRef,
  ) => {
    const [isDraggingAnyItem, setIsDraggingAnyItem] = useState<boolean>(false);
    const sensors = useSensors(
      useSensor(MouseSensor, {
        activationConstraint: {
          distance: 3,
        },
      }),
      useSensor(TouchSensor, {
        activationConstraint: {
          distance: 3,
        },
      }),
    );

    const trackRateLimit = useRateLimitWarning(
      'OverviewList.onDragEnd rate limit exceeded',
      10,
      1000,
      true,
      {
        feature: 'creation-experience',
      },
    );

    const listStyling: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignContent: 'flex-start',
      listStyle: 'none',
      width: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      flex: 1,
      paddingTop: '6px',
      // positioned for scroll on focus to work properly
      position: 'relative',
    };

    const onDragStart = useCallback(
      (args: DragStartEvent) => {
        const id = args.active.id;
        if (!id) return;
        setIsDraggingAnyItem(true);
        onDisableKeyboardNavigation(true);
      },
      [onDisableKeyboardNavigation],
    );

    const onDragEnd = (result: DragEndEvent) => {
      trackRateLimit();
      onDisableKeyboardNavigation(false);
      const { active, over } = result;
      const oldIndex = items.findIndex((i) => i.id === active!.id);
      const newIndex = items.findIndex((i) => i.id === over?.id);
      setIsDraggingAnyItem(false);
      //Invalid location (outside the list)
      if (active.id === over?.id || newIndex === -1) {
        return;
      }

      if (isDraggingMultiple(oldIndex, selectedItemsIds, items)) {
        const destinationIndex = getIndexForMultiDrag(
          oldIndex,
          selectedItemsIds,
          items,
          newIndex,
        );
        onDragEndFromProps(selectedItemsIds, destinationIndex);
      } else {
        onDragEndFromProps([result.active.id as string], newIndex);
      }
    };

    return (
      <DndContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext disabled={isDragDisabled} items={items}>
          <ol
            id="editor-overview-list"
            data-testid="editor-overview-list"
            ref={forwardedRef}
            style={listStyling}
          >
            {items.map((item, index) => {
              return (
                <RenderIfVisible
                  // key={index} is needed here in order for intersection observer to work properly when
                  // reordering slides. Cannot use {item.id} as a key because after slides are re-ordered by drag
                  // and drop, the id will be the same and intersection observer will not work correctly.
                  // Since we now render just visible elements and the order of slides changes only when dragging-and dropping
                  // it's safe to use index as a key.
                  key={index}
                  root={document.getElementById('editor-overview-list')}
                >
                  <Item
                    item={item}
                    index={index}
                    key={item.id}
                    selected={selectedItemsIds.includes(item.id)}
                    isMultipleSelected={selectedItemsIds.length > 1}
                    renderItem={renderItem}
                    active={item.id === activeItemId}
                    isDraggingAnyItem={isDraggingAnyItem}
                  />
                </RenderIfVisible>
              );
            })}
          </ol>
          <DragOverlay>
            <OverviewDragOverlay
              items={items}
              activeItemId={activeItemId}
              numberOfDraggedItems={selectedItemsIds.length || 1}
              bgColor={seriesBgColor}
              textColor={seriesTextColor}
            />
          </DragOverlay>
        </SortableContext>
      </DndContext>
    );
  },
);

const Item = ({
  item,
  index,
  selected,
  active,
  isMultipleSelected,
  isDraggingAnyItem,
  renderItem,
}: {
  item: OverviewListItemType;
  index: number;
  selected: boolean;
  active: boolean;
  isMultipleSelected: boolean;
  isDraggingAnyItem: boolean;
  renderItem: RenderItem;
}) => {
  const {
    listeners,
    setNodeRef,
    transform,
    transition,
    attributes,
    isDragging,
  } = useSortable({
    id: item.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return renderItem({
    listeners,
    attributes,
    item,
    selected,
    active,
    index,
    isDraggedElement: isDragging,
    isDragging: isMultipleSelected && selected && isDraggingAnyItem,
    setNodeRef,
    style,
  });
};
