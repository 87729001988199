import { createContext, useContext } from 'react';
import { TrackingContext } from '@mentimeter/http-clients';

const CommentTrackingContext = createContext<TrackingContext>(
  TrackingContext.Editview,
);

const useCommentsTrackingContext = () => {
  const value = useContext(CommentTrackingContext);
  return value;
};
const CommentsTrackingContextProvider = CommentTrackingContext.Provider;
export { CommentsTrackingContextProvider, useCommentsTrackingContext };
