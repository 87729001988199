import { captureException, MentiError } from '@mentimeter/errors/sentry';
import { generateHTML, type JSONContent } from '@tiptap/core';
import Mention from '@tiptap/extension-mention';
import Document from '@tiptap/extension-document';
import TextExtension from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import DOMPurify from 'dompurify';

export function tryGenerateHTML(content: JSONContent) {
  try {
    return DOMPurify.sanitize(
      generateHTML(content, [Document, TextExtension, Paragraph, Mention]),
    );
  } catch (error) {
    const e = new MentiError(`Error generating HTML: ${error}`, {
      feature: 'comments-pane-editor',
    });
    captureException(e);
    return '';
  }
}
