import { CANVAS_OUTLINE_COLOR } from '@mentimeter/editor-canvas';
import { lighten } from '@mentimeter/ragnar-colors';
import { Box } from '@mentimeter/ragnar-ui';
import React, { type PropsWithChildren } from 'react';

export const EditorCanvasAreaSlideLayout = React.memo(
  ({
    isCanvasInteractive,
    isSlideActiveAndSidebarClosed,
    children,
  }: PropsWithChildren<{
    isCanvasInteractive: boolean;
    isSlideActiveAndSidebarClosed: boolean;
  }>) => {
    return (
      <Box
        id="editor-canvas-area-slide"
        width="100%"
        height="100%"
        alignItems="center"
        borderRadius="lg"
        overflow="hidden"
        extend={({ theme }) => ({
          transition: `outline-color ${theme.durations[0]}s`,
          cursor: isCanvasInteractive ? 'pointer' : 'default',
          outlineStyle: 'solid',
          outlineColor:
            isCanvasInteractive && isSlideActiveAndSidebarClosed
              ? CANVAS_OUTLINE_COLOR
              : 'transparent',
          outlineWidth: '2px',
          ':not(:has(.canvasArea:hover)):hover': {
            outlineColor: isSlideActiveAndSidebarClosed
              ? undefined
              : isCanvasInteractive
                ? lighten(CANVAS_OUTLINE_COLOR, 0.5)
                : 'transparent',
          },
          '&:after': {
            borderRadius: 'inherit',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            boxShadow: `inset 0 0 0 ${theme.borders[2] ?? 0}px ${
              isCanvasInteractive && isSlideActiveAndSidebarClosed
                ? '#FFF'
                : 'transparent'
            }`,
            pointerEvents: 'none',
          },
          ':not(:has(.canvasArea:hover)):hover:after': {
            boxShadow: `inset 0 0 0 ${theme.borders[2] ?? 0}px ${
              isCanvasInteractive ? '#FFF' : 'transparent'
            }`,
          },
        })}
      >
        {children}
      </Box>
    );
  },
);
