import { createContext } from 'react';

export interface KeyboardHandlerContext {
  disable: (value: boolean) => void;
}

export const KeyboardHandlerContext = createContext<
  KeyboardHandlerContext | undefined
>(undefined);
KeyboardHandlerContext.displayName = 'KeyboardHandlerContext';
