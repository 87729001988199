import { trackUser } from '@api/tracking/client';
import {
  TrackingContext,
  type UserPresentationRole,
} from '@mentimeter/http-clients';

export function trackClickedCommentIcon({
  context,
  role,
  seriesId,
  isSharedPresentation,
  hasResults,
  permission,
}: {
  role: UserPresentationRole;
  context: TrackingContext;
  seriesId: string;
  isSharedPresentation: boolean;
  hasResults: boolean;
  permission: string;
}) {
  trackUser({
    event: 'Clicked comment icon',
    properties: {
      context,
      role,
      'series id': seriesId,
      'shared presentation': isSharedPresentation,
      'has results': hasResults,
      permission,
    },
  });
}

export function trackDuplicatedSlides({
  role,
  amount,
  total,
}: {
  role: UserPresentationRole;
  amount: number;
  total: number;
}) {
  trackUser({
    event: 'Duplicated slides',
    properties: {
      placement: 'Menu',
      context: TrackingContext.Editview,
      role,
      amount,
      'presentation total slides amount': total,
    },
  });
}

export function trackDeletedSlide({
  role,
  amount,
}: {
  role: UserPresentationRole;
  amount: number;
}) {
  trackUser({
    event: 'Deleted slides',
    properties: {
      placement: 'Menu',
      context: TrackingContext.Editview,
      role,
      amount,
    },
  });
}

export function trackTriggeredContextMenuButton({
  role,
}: {
  role: UserPresentationRole;
}) {
  trackUser({
    event: 'Triggered context menu',
    properties: {
      placement: 'Overview',
      context: TrackingContext.Editview,
      trigger: 'Icon',
      role,
    },
  });
}

export function trackTriggeredContextMenuMouse({
  role,
}: {
  role: UserPresentationRole;
}) {
  trackUser({
    event: 'Triggered context menu',
    properties: {
      placement: 'Overview',
      context: TrackingContext.Editview,
      trigger: 'Right click',
      role,
    },
  });
}

export function trackMovedSlides({
  role,
  amount,
}: {
  role: UserPresentationRole;
  amount: number;
}) {
  trackUser({
    event: 'Moved slides',
    properties: {
      placement: 'Overview',
      context: TrackingContext.Editview,
      role,
      amount,
    },
  });
}

export function trackSkippedSlides({
  role,
  amount,
}: {
  role: UserPresentationRole;
  amount: number;
}) {
  trackUser({
    event: 'Skipped slide',
    properties: {
      placement: 'Menu',
      context: TrackingContext.Editview,
      role,
      amount,
    },
  });
}

export function trackUnskippedSlides({
  role,
  amount,
}: {
  role: UserPresentationRole;
  amount: number;
}) {
  trackUser({
    event: 'Unskipped slide',
    properties: {
      placement: 'Menu',
      context: TrackingContext.Editview,
      role,
      amount,
    },
  });
}
