import { useDeviceChecker } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui';
import { useEffect, useRef, type ReactNode } from 'react';
import { TabPanel } from '../../components/tabs/TabPanel';
import {
  SIDEBAR_BAR_HEIGHT_MOBILE,
  SIDEBAR_BAR_TABLET_WIDTH,
  SIDEBAR_BAR_WIDTH,
  SIDEBAR_PANE_WIDTH,
} from '../../constants';
import { PaneTitle } from './PaneTitle';

interface PaneContainerProps {
  activeQuestionId: string;
  title: string;
  children: ReactNode;
  onDismiss: () => void;
  onBackClick?: (() => void) | undefined;
}

export const PaneContainer = ({
  activeQuestionId,
  title,
  children,
  onDismiss,
  onBackClick,
}: PaneContainerProps) => {
  const { isMobile } = useDeviceChecker();
  const paneContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (paneContainerRef.current) {
      paneContainerRef.current.scrollTop = 0;
    }
  }, [activeQuestionId]);

  return (
    <TabPanel
      position="absolute"
      top={isMobile ? undefined : 0}
      right={[0, SIDEBAR_BAR_TABLET_WIDTH, SIDEBAR_BAR_WIDTH]}
      bottom={[`calc(${SIDEBAR_BAR_HEIGHT_MOBILE} - 1px)`, 0, null]}
      alignItems="stretch"
      mb={['space0', 'space4']}
      mt="space4"
      mr={['space0', 'space4']}
      ml={[0, 'space4', 0]}
      borderBottomRightRadius={['none', '2xl']}
      borderBottomLeftRadius={['none', '2xl']}
      borderTopLeftRadius="2xl"
      borderTopRightRadius="2xl"
      borderStyle="solid"
      borderColor="borderWeak"
      borderWidth={1}
      bg="bg"
      zIndex={[30, null, 'auto']}
      width={['100%', `${SIDEBAR_PANE_WIDTH}px`]}
      height={isMobile ? '65vh' : undefined}
    >
      <PaneTitle
        label={title}
        onDismiss={onDismiss}
        onBackClick={onBackClick}
      />
      <Box
        ref={paneContainerRef}
        alignItems="stretch"
        flex="1 1 auto"
        py="space6"
        px="space8"
        extend={() => ({
          overflowX: 'hidden',
          overflowY: 'auto',
        })}
      >
        {children}
      </Box>
    </TabPanel>
  );
};
