import type { CanvasArea } from '@mentimeter/editor-essentials/types';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

interface State {
  activeCanvasArea: CanvasArea;
  setActiveCanvasArea: (data: CanvasArea) => void;
}

export const useActiveCanvasAreaStore = createWithEqualityFn<State>(
  (set) => ({
    activeCanvasArea: 'slide',
    setActiveCanvasArea: (data: State['activeCanvasArea']) =>
      set((state) => ({ ...state, activeCanvasArea: data })),
  }),
  shallow,
);
