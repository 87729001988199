import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { EditorOverviewWrapper } from '@mentimeter/editor-overview-ui';
import { useOverviewVisibleStore } from '@mentimeter/editor-state';
import { Suspense, type PropsWithChildren } from 'react';

interface EditorOverviewDataHandlerProps {
  activeSlideIndex: number;
  amountOfSlides: number;
}

export const EditorOverviewDataHandler = ({
  activeSlideIndex,
  amountOfSlides,
  children,
}: PropsWithChildren<EditorOverviewDataHandlerProps>) => {
  const [isOverviewVisible, setIsOverviewVisible] = useOverviewVisibleStore();

  const handleOverviewVisibleToggle = () =>
    setIsOverviewVisible(!isOverviewVisible);

  return (
    <EditorOverviewWrapper
      activeSlideIndex={activeSlideIndex}
      amountOfSlides={amountOfSlides}
      isOverviewVisible={isOverviewVisible}
      onOverviewVisibleToggle={handleOverviewVisibleToggle}
    >
      <ErrorBoundary
        feature="creation-experience"
        errorMessage="Could not load Overview List"
      >
        <Suspense fallback={null}>{children}</Suspense>
      </ErrorBoundary>
    </EditorOverviewWrapper>
  );
};
