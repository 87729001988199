import { Box } from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';

export function CanvasSidebarLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Box flex="1 1 auto" height="100%" alignItems="stretch">
      <Box flex="1 1 auto" flexDirection="row">
        {children}
      </Box>
    </Box>
  );
}
