import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { useOnce } from '@mentimeter/react-hooks';
import { userCache } from '@mentimeter/user';
import { postRecentRequest } from '@core-api/presentation-collection/presentation-collection/recent';

export const useRegisterViewSeries = (
  seriesId: string,
  shouldRegisterView: boolean,
) => {
  useOnce(shouldRegisterView, async () => {
    const region = userCache.region;
    const sessionToken = userCache.getToken();

    const response = await fetch(
      postRecentRequest({ region, userAuth: sessionToken, id: seriesId }),
    );
    if (!response.ok && response.status !== 401) {
      const e = new MentiError('Failed to register viewed series', {
        feature: 'recent-presentations',
      });
      captureException(e);
    }
  });
};
