import { useMatch } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui';
import type React from 'react';
import type { ReactNode } from 'react';

export function CanvasContentLayout({
  children,
}: {
  children: ReactNode;
}) {
  const isMobileDevice = useMatch({ lessThan: 2 });
  return (
    <Box
      flex="1 1 auto"
      width="100%"
      justifyContent="flex-start"
      mt={isMobileDevice ? 'space12' : 0}
      mb={isMobileDevice ? 'space18' : 0}
      gap="space4"
    >
      {children}
    </Box>
  );
}
