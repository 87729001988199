import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { useOrganizationMembersPaginated } from '@mentimeter/workspace-data-hooks';
import { useEditorSeries } from '@mentimeter/editor-data-hooks';
import type { MentionDetails } from '../components/CommentDetails';
import { MentionDetailsPopover } from '../components/MentionDetailsPopover';
import { useMentionableCollaborators } from './MentionListDataHandler';
import type { MentionPopOver } from './types';

interface MentionDetailsPopOverDataHandlerProps {
  onOpenChange: () => void;
  mentionDetails: MentionDetails;
}

const MentionDetailsCollaborationPopOverDataHandler = ({
  onOpenChange,
  mentionDetails,
}: MentionDetailsPopOverDataHandlerProps) => {
  const seriesId = useActiveSeriesId();
  const { collaborators } = useMentionableCollaborators(seriesId);

  const items: MentionPopOver[] = collaborators
    .filter(({ entity }) => String(entity.id) === mentionDetails.id)
    .map((collaborator) => ({
      id: String(collaborator.entity.id),
      name: collaborator.entity.name,
      email: collaborator.entity.email,
      profilePictureUrl: collaborator.entity.profilePictureUrl,
    }));

  return (
    <MentionDetailsPopover
      showPopover={mentionDetails.show}
      onOpenChange={onOpenChange}
      position={mentionDetails.position}
      item={items[0]}
    />
  );
};

const MentionDetailsWorkspacePopOverDataHandler = ({
  onOpenChange,
  mentionDetails,
}: MentionDetailsPopOverDataHandlerProps) => {
  const queryString = `?&search=${encodeURIComponent(mentionDetails.label)}&order_by=${encodeURIComponent('user_name')}&order=${encodeURIComponent('asc')}&filter=${encodeURIComponent('status:active roles:owner:admin:user')}`;

  const { members } = useOrganizationMembersPaginated(queryString);

  const items: MentionPopOver[] = members
    .filter(({ id }) => String(id) === mentionDetails.id)
    .map((member) => ({
      id: member.id,
      name: member.name ?? '',
      email: member.email,
      profilePictureUrl: member.profile_picture_url,
    }));

  return (
    <MentionDetailsPopover
      showPopover={mentionDetails.show}
      onOpenChange={onOpenChange}
      position={mentionDetails.position}
      item={items[0]}
    />
  );
};

export const MentionDetailsPopOverDataHandler = ({
  onOpenChange,
  mentionDetails,
}: {
  onOpenChange: () => void;
  mentionDetails: MentionDetails;
}) => {
  const { series } = useEditorSeries(useActiveSeriesId());

  if (series?.workspace_id !== null)
    return (
      <MentionDetailsWorkspacePopOverDataHandler
        mentionDetails={mentionDetails}
        onOpenChange={onOpenChange}
      />
    );

  return (
    <MentionDetailsCollaborationPopOverDataHandler
      mentionDetails={mentionDetails}
      onOpenChange={onOpenChange}
    />
  );
};
