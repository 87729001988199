import { Box } from '@mentimeter/ragnar-ui';
import type { BoxT } from '@mentimeter/ragnar-ui';
import React from 'react';
import { useTabbedInterface } from './hooks';

interface TabPanelProps extends BoxT {
  children: React.ReactNode;
}

export const TabPanel = ({ children, ...boxProps }: TabPanelProps) => {
  const { id: tabbedInterfaceId, activeTabId } = useTabbedInterface();
  return (
    <Box
      {...boxProps}
      id={`${tabbedInterfaceId}-tabpanel-${activeTabId}`}
      role="tabpanel"
      aria-labelledby={`${tabbedInterfaceId}-tab-${activeTabId}`}
    >
      {children}
    </Box>
  );
};
