import type { CanvasArea } from '@mentimeter/editor-essentials/types';
import type { ModuleId } from '@mentimeter/http-clients';
import { Icon } from '@mentimeter/question-modules-importer';
import {
  PencilRulerSolidIcon,
  TextFormatIcon,
} from '@mentimeter/ragnar-visuals';

interface EditPaneIconProps {
  slideType: ModuleId;
  canvasArea: CanvasArea;
}

export const EditPaneIcon = ({ slideType, canvasArea }: EditPaneIconProps) => {
  if (canvasArea === 'visualization') {
    return <Icon moduleId={slideType} textColor="currentColor" isSmall />;
  }
  if (canvasArea === 'title') {
    return <TextFormatIcon aria-hidden={true} size={4} color="inherit" />;
  }

  return <PencilRulerSolidIcon aria-hidden={true} size={4} color="inherit" />;
};
