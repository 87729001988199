import { Box, Button } from '@mentimeter/ragnar-ui';
import { useDevice } from '@mentimeter/ragnar-device';
import React from 'react';
import { PlusIcon } from '@mentimeter/ragnar-visuals';

export const ADD_SLIDE_BUTTON_ID = 'add-slide-button';

const RotatingIcon = ({ shouldRotate }: { shouldRotate: boolean }) => {
  return (
    <Box
      mr="space1"
      aria-hidden={true}
      extend={({ theme }) => ({
        transition: `transform ${theme.durations[1]}s ease`,
        transform: shouldRotate ? 'rotate(45deg)' : 'rotate(0)',
      })}
    >
      <PlusIcon size={1} color="inherit" />
    </Box>
  );
};

interface AddSlideButtonComponentProps {
  disabled: boolean;
  handleClickAddSlideButton: () => void;
  showAddSlidePopover: boolean;
  buttonExtend: any;
}

export const AddSlideButtonComponent = React.forwardRef(
  (
    {
      disabled,
      handleClickAddSlideButton,
      showAddSlidePopover,
      buttonExtend,
    }: AddSlideButtonComponentProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const { breakpoint } = useDevice();
    const buttonSize = breakpoint > 3 ? 'default' : 'compact';

    return (
      <Button
        ref={ref}
        size={buttonSize}
        disabled={disabled}
        variant="primary"
        onClick={handleClickAddSlideButton}
        id={ADD_SLIDE_BUTTON_ID}
        data-testid={
          showAddSlidePopover ? 'cancel-add-slide-btn' : 'add-slide-btn'
        }
        extend={buttonExtend}
      >
        <RotatingIcon shouldRotate={showAddSlidePopover} />
        New slide
      </Button>
    );
  },
);
