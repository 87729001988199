import { useContext } from 'react';
import { KeyboardHandlerContext } from './KeyboardHandlerContext';

export const useKeyboardHandler = () => {
  const context = useContext(KeyboardHandlerContext);
  if (context === undefined) {
    throw new Error(
      'useKeyboardHandler must be used within an KeyboardHandlerProvider!',
    );
  }
  return context;
};
