import React, { useEffect, useRef, useState } from 'react';
import type { UserT } from '@mentimeter/user';
import { Box, Text } from '@mentimeter/ragnar-ui';
import { Comment as CommentPictogram } from '@mentimeter/ragnar-visuals';
import type { Comment } from '@core-api/comments/types/response';
import { CommentCard } from './CommentCard';

const EmptyState: React.FC<{
  showResolvedComments: boolean;
}> = ({ showResolvedComments }) => {
  return (
    <Box alignItems="center" justifyContent="center" maxHeight="100%">
      <CommentPictogram />
      <Text
        fontSize="fontSize2"
        textAlign="center"
        color="textWeaker"
        mt="space2"
      >
        {showResolvedComments
          ? 'This is where your resolved comments will appear'
          : 'Write a note or @mention someone on this slide'}
      </Text>
    </Box>
  );
};

export const CommentsList = ({
  comments,
  user,
  commentId,
  showResolvedComments = false,
}: {
  comments: Comment[];
  user: UserT;
  commentId: string | undefined;
  showResolvedComments?: boolean;
}) => {
  const [highlightedCommentId, setHighlightedCommentId] = useState<
    string | null
  >(null);
  const commentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (commentId && commentRefs.current[commentId]) {
      commentRefs.current[commentId]?.scrollIntoView({ behavior: 'smooth' });
      setHighlightedCommentId(commentId);
      setTimeout(() => setHighlightedCommentId(null), 2000);
    }
  }, [commentId]);

  const parents = comments.filter((c) => !c.parentId);

  return parents.length > 0 ? (
    parents.map((comment) => {
      const children = comments.filter(
        ({ parentId }) => parentId === comment.id,
      );
      const lastCommentId = children[children.length - 1]?.id;
      const highlight = highlightedCommentId === comment.id;

      return (
        <Box
          my="space1"
          key={comment.id}
          width="100%"
          borderRadius="lg"
          bg={highlight ? 'secondaryWeak' : 'surface'}
        >
          <CommentCard
            ref={(element) => {
              commentRefs.current[comment.id] = element;
            }}
            isOwner={user.id === comment.authorId}
            isReply={false}
            comment={comment}
          />

          {children.map((child) => {
            return (
              <CommentCard
                ref={(element) => {
                  commentRefs.current[child.id] = element;
                }}
                key={child.id}
                isOwner={user.id === child.authorId}
                isReply={true}
                comment={child}
                lastCommentId={lastCommentId}
              />
            );
          })}
        </Box>
      );
    })
  ) : (
    <EmptyState showResolvedComments={showResolvedComments} />
  );
};
