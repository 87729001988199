import { Clickable, Text, Tooltip } from '@mentimeter/ragnar-ui';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { forwardRef } from 'react';
import { useMatch } from '@mentimeter/ragnar-device';

const clickableExtend: Extend = ({ theme }) => {
  return {
    transition: `${theme.durations[0]}s ease-in-out`,
    color: theme.colors.text,
    background: 'transparent',
    borderColor: 'transparent',
    '&:enabled': {
      '&:hover': {
        opacity: 1,
        background: theme.colors.neutralWeakest,
        borderRadius: theme.kosmosBorderRadius.xl,
        boxShadow: ` inset 0 0 0 ${theme.kosmosSpacing.space1}px ${theme.colors.bg}`,
      },
    },
  };
};

interface SidebarButtonProps {
  icon: React.ReactNode;
  title: string;
  disabled?: boolean;
  tooltipDescription?: string;
  onClick?: () => void;
}

export const SidebarButton = forwardRef<HTMLButtonElement, SidebarButtonProps>(
  ({ icon, title, disabled, tooltipDescription, onClick }, ref) => {
    const isMobile = useMatch({ lessThan: 2 });
    const isTablet = useMatch({ equals: 2 });

    return (
      <Clickable
        ref={ref}
        disabled={disabled}
        alignItems="center"
        justifyContent="center"
        height={['undefined', '56px', '84px']}
        minWidth={['88px', '56px', '96px']}
        p="space2"
        borderRadius="2xl"
        onClick={onClick}
        extend={clickableExtend}
        id={`sidebar-tab-${title}`}
        flex="1 1 auto"
      >
        {icon}
        {!isTablet && (
          <Text
            mt="space1.5"
            fontSize={isMobile ? '75' : '87.5'}
            color="inherit"
            fontWeight="semiBold"
          >
            {title}
          </Text>
        )}
        {tooltipDescription && (
          <Tooltip
            referenceId={`sidebar-tab-${title}`}
            description={tooltipDescription}
            placement="left"
          />
        )}
      </Clickable>
    );
  },
);
