import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { CommentIndicator } from '@mentimeter/ragnar-visuals';
import { useCommentsStore } from '@mentimeter/comments-from-audience';
import { useSeries } from '../../../data-mediation';
import { Indication } from '../Indication';
import { IndicationNumber } from '../IndicationNumbers';

const C = () => {
  const awaitingComments = useCommentsStore((state) => state.backlog.length);
  return (
    <Box alignItems="center" data-testid="cfa-indicator">
      <Indication>
        <IndicationNumber number={awaitingComments} />
        <CommentIndicator size={3} color="text" />
      </Indication>
    </Box>
  );
};

export const CfaIndicator = () => {
  const { comments_enabled } = useSeries();

  if (!comments_enabled) return null;

  return <C />;
};
