import { useCallback } from 'react';
import { questionsCacheKey } from '@mentimeter/core-hooks';
import { useEditorQuestions } from '@mentimeter/editor-data-hooks';
import { revalidateComments } from '../hooks/useComments';

export const useRevalidateEditorComments = (
  seriesId: string,
  questionAdminKey: string,
) => {
  const { revalidate: revalidateThumbnails } = useEditorQuestions(seriesId, {
    getQuestionsCacheKey: questionsCacheKey,
  });

  const revalidateEditorComments = useCallback(() => {
    revalidateComments(questionAdminKey);
    revalidateThumbnails();
  }, [questionAdminKey, revalidateThumbnails]);

  return { revalidateEditorComments };
};
