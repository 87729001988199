import { Box, Text } from '@mentimeter/ragnar-ui';
import { Warning } from '@mentimeter/ragnar-visuals';
import { useSearchParams } from '@mentimeter/next-navigation';
import { useUser } from '@mentimeter/user';
import { useEffect, useState } from 'react';
import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { useEditorSeries } from '@mentimeter/editor-data-hooks';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import type { TrackingContext } from '@mentimeter/http-clients';
import { addBreadcrumb } from '@mentimeter/errors/sentry';
import { CommentsList } from '../components/CommentsList';
import { useComments } from '../hooks/useComments';
import { CommentsViewSwitcher } from '../components/CommentsViewSwitcher';
import { CommentsTrackingContextProvider } from '../components/CommentsTrackingContextProvider';
import { CommentsControlDataHandler } from './CommentsControlDataHandler';

const ErrorState: React.FC = () => (
  <Box alignItems="center" justifyContent="center">
    <Warning />
    <Text
      fontSize="fontSize2"
      textAlign="center"
      color="textWeaker"
      mt="space2"
    >
      Something went wrong. Please try again later.
    </Text>
  </Box>
);

export const CommentsPaneDataHandler = ({
  trackingContext,
  visible = false,
}: {
  trackingContext: TrackingContext;
  visible: boolean;
}) => {
  const { user } = useUser();
  const { series } = useEditorSeries(useActiveSeriesId());
  const questionAdminKey =
    useSearchParams()?.get('question') || series?.questions[0]?.admin_key || '';
  const commentId = useSearchParams()?.get('comment') || '';
  const { comments, error, isLoading } = useComments(questionAdminKey);
  const [showResolvedComments, setShowResolvedComments] = useState(false);

  const showResolvedParam = useSearchParams()?.get('resolved');

  useEffect(() => {
    setShowResolvedComments(Boolean(showResolvedParam));
  }, [questionAdminKey, showResolvedParam]);

  useEffect(() => {
    if (!series) return;

    addBreadcrumb({
      message: `Comments pane ${visible ? 'opened' : 'closed'}`,
      data: {
        seriesId: series?.slideDeckId ?? '',
        questionAdminKey,
      },
    });
  }, [visible, series, questionAdminKey]);

  if (!user || isLoading) return null;

  const isOwner = user.id === series?.owner_id;
  const showActiveComments = !showResolvedComments;
  const commentsToList = comments.filter((comment) =>
    showResolvedComments ? comment.resolvedAt : !comment.resolvedAt,
  );

  return (
    <ErrorBoundary
      feature="comments-pane-editor"
      errorMessage="Error Occurred - CommentsPaneDataHandler"
      fallback={<ErrorState />}
    >
      <CommentsTrackingContextProvider value={trackingContext}>
        <Box
          display={visible ? 'flex' : 'none'}
          alignItems="stretch"
          height="100%"
        >
          <Box flex="1" overflow="auto" display="block">
            {error ? (
              <ErrorState />
            ) : (
              <CommentsList
                comments={commentsToList}
                user={user}
                commentId={commentId}
                showResolvedComments={showResolvedComments}
              />
            )}
          </Box>
          <CommentsViewSwitcher
            showResolvedComments={Boolean(showResolvedComments)}
            resolvedCommentsCount={
              comments.filter(
                (comment) => comment.resolvedAt && !comment.parentId,
              ).length
            }
            onSwitchView={() =>
              setShowResolvedComments?.(!showResolvedComments)
            }
          />
          {showActiveComments && (
            <CommentsControlDataHandler
              showControlButtonsInitially={false}
              cta="Comment"
              placeholder="Add a comment"
              parentCommentId={null}
              isOwner={isOwner}
              minHeight={120}
            />
          )}
        </Box>
      </CommentsTrackingContextProvider>
    </ErrorBoundary>
  );
};
