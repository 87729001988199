/**
 * This is a temporary component to indicate that a slide has been migrated.
 * It will be removed then the slide migration project is finished.
 */

interface MigratedSlideIndicatorProps {
  isMigrated: boolean;
}

export const MigratedSlideIndicator = ({
  isMigrated,
}: MigratedSlideIndicatorProps) => {
  return isMigrated && process.env.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'prod' ? (
    <div
      role="note"
      style={{
        position: 'absolute',
        top: 2,
        right: 18,
        padding: '4px',
        background: 'orange',
        color: 'white',
        fontSize: '10px',
        fontWeight: 'bold',
        zIndex: 100,
      }}
    >
      Migrated
    </div>
  ) : null;
};
