import { Box } from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';

export function CanvasAreaLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Box flex="1 1 auto" height="100%" p={3}>
      {children}
    </Box>
  );
}
