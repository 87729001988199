// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface DeleteCommentsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The comment id to delete */
  id: string;
}

export function deleteCommentsRequest(
  requestParams: DeleteCommentsRequest,
): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/comments/${requestParams.id}`;
  return new Request(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
