export interface TabInternal {
  ref: React.MutableRefObject<HTMLElement | null>;
  propsRef: React.MutableRefObject<{ id: string; disabled?: boolean }>;
}

// sorts items according to their order in the DOM
export const sortItems = (items: TabInternal[]) => {
  const sortedItems = items.sort((a, b) => {
    if (a.ref.current && b.ref.current) {
      const position = a.ref.current.compareDocumentPosition(b.ref.current);
      if (position & Node.DOCUMENT_POSITION_FOLLOWING) return -1;
      if (position & Node.DOCUMENT_POSITION_PRECEDING) return 1;
    }
    return 0;
  });
  return sortedItems;
};

export const getItemByPosition = (
  items: TabInternal[],
  activeItem?: TabInternal,
  direction?: 'prev' | 'next',
) => {
  const activeIndex = activeItem ? items.indexOf(activeItem) : 0;

  if (!direction) {
    return items.find((item) => !item.propsRef.current.disabled);
  }

  // shift the array so it starts from the item after the active item
  const newStart = items.slice(activeIndex + 1);
  const restWithoutActiveItem = items.slice(0, activeIndex);
  const reorderedItems = [...newStart, ...restWithoutActiveItem];

  if (direction === 'prev') {
    return reorderedItems
      .reverse()
      .find((item) => !item.propsRef.current.disabled);
  }
  if (direction === 'next') {
    return reorderedItems.find((item) => !item.propsRef.current.disabled);
  }

  return;
};
