import {
  DropdownMenuItem,
  ModalRoot,
  ModalTrigger,
  Text,
} from '@mentimeter/ragnar-ui';
import { TrashIcon } from '@mentimeter/ragnar-visuals';

export const DeleteActionModalTrigger = ({
  onOpenDeleteModalChange,
}: {
  onOpenDeleteModalChange: () => void;
}) => {
  return (
    <ModalRoot onOpenChange={onOpenDeleteModalChange}>
      <ModalTrigger>
        <DropdownMenuItem flexDirection="row" alignItems="center">
          <TrashIcon color="negative" />
          <Text ml="space2" color="negative" fontSize="87.5">
            Delete
          </Text>
        </DropdownMenuItem>
      </ModalTrigger>
    </ModalRoot>
  );
};
