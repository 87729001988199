import Link from '@tiptap/extension-link';

export const SanitizedLink = Link.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      autolink: true,
      openOnClick: false,
      protocols: ['mailto'],
    };
  },
  parseHTML() {
    return [
      {
        tag: 'a[href]',
        getAttrs: (dom) => {
          const href = (dom as HTMLElement).getAttribute('href');

          if (!href) {
            return false;
          }
          if (
            this.options.protocols.includes('mailto') &&
            href.startsWith('mailto:')
          ) {
            return { href };
          }
          if (href.startsWith('https://')) {
            return { href };
          }
          return false;
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const href = HTMLAttributes.href;

    if (
      this.options.protocols.includes('mailto') &&
      href.startsWith('mailto:')
    ) {
      return [
        'a',
        {
          href,
        },
        0,
      ];
    }
    if (href.startsWith('https://')) {
      return [
        'a',
        {
          href,
          target: '_blank',
          rel: 'noopener noreferrer nofollow',
        },
        0,
      ];
    }

    return ['a', {}, 0];
  },
});
