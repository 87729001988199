import type { Comment } from '@core-api/comments/types/response';
import { Box, Button } from '@mentimeter/ragnar-ui';
import { addUnit } from '@mentimeter/ragnar-utils';
import { useEffect, useRef, useState } from 'react';
import { MentionDetailsPopOverDataHandler } from '../data-handlers/MentionDetailsPopoverDataHandler';
import { safeJSONParse } from '../util/safeJSONParse';
import { tryGenerateHTML } from '../util/tryGenerateHTML';
import type { MentionDetails } from './CommentDetails';

const MAX_COLLAPSED_HEIGHT = 85;
const MAX_EXPANDED_HEIGHT = 345;
const TRANSITION_TIME_IN_SEC = 0.5;
const TEXT_TRUNCATION_DELAY_IN_MS = TRANSITION_TIME_IN_SEC * 1000;

export const CommentText = ({
  comment,
  setShowEdited,
}: { comment: Comment; setShowEdited: (arg: boolean) => void }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCollapsing, setIsCollapsing] = useState(false); // Track collapsing state
  const [mentionDetails, setMentionDetails] = useState<MentionDetails>({
    id: '',
    label: '',
    position: null,
    show: false,
  });
  const contentRef = useRef<HTMLDivElement>(null);
  const content = safeJSONParse(comment.styledText);
  const [showSeeMoreButton, setShowSeeMoreButton] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const shouldShowSeeMoreButton =
        contentRef.current.scrollHeight > MAX_COLLAPSED_HEIGHT;
      setShowSeeMoreButton(shouldShowSeeMoreButton);

      if (!isExpanded && shouldShowSeeMoreButton) {
        setShowEdited(false);
      } else {
        setShowEdited(true);
      }
    }
  }, [content, isExpanded, setShowEdited]);

  if (!content) return null;

  const html = tryGenerateHTML(content);

  const toggleExpand = () => {
    if (isExpanded) {
      setIsCollapsing(true);
      setTimeout(() => {
        setIsCollapsing(false);
      }, TEXT_TRUNCATION_DELAY_IN_MS);
    }
    setIsExpanded(!isExpanded);
  };

  const handleMentionClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const target = event.target as HTMLElement;
    if (!target) return;

    const mentionId = target.getAttribute('data-id');
    if (mentionId) {
      event.stopPropagation(); // Prevent triggering any other click events, like toggleExpand

      const mentionRect = event.currentTarget.getBoundingClientRect();
      const mentionLabel = target.getAttribute('data-label');
      setMentionDetails({
        id: mentionId,
        label: mentionLabel || '',
        position: mentionRect,
        show: true,
      });
    }
  };

  return (
    <>
      <Box
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: html }}
        maxHeight={
          isExpanded
            ? addUnit(MAX_EXPANDED_HEIGHT)
            : addUnit(MAX_COLLAPSED_HEIGHT)
        }
        overflow={isExpanded ? 'auto' : 'hidden'}
        onClick={isExpanded ? toggleExpand : undefined}
        onClickCapture={(event) => handleMentionClick(event)}
        display="block"
        extend={({ theme }) => ({
          transition: `max-height ${TRANSITION_TIME_IN_SEC}s ease`,
          cursor: isExpanded ? 'pointer' : 'default',
          '> p': {
            marginBottom: addUnit(theme.kosmosSpacing.space2),
            display: '-webkit-box',
            WebkitLineClamp: !isExpanded && !isCollapsing ? 4 : 'unset',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '> p > span[data-type="mention"]': {
            color: theme.colors.brand,
            cursor: 'pointer',
          },
        })}
      />
      <MentionDetailsPopOverDataHandler
        onOpenChange={() =>
          setMentionDetails((state) => ({
            ...state,
            show: !state.show,
          }))
        }
        mentionDetails={mentionDetails}
      />
      {!isExpanded && showSeeMoreButton && (
        <Button
          variant="subtle"
          style={{
            display: 'inline-block',
            fontSize: '87.5%',
            fontWeight: 'normal',
          }}
          extend={({ theme }) => ({
            color: theme.colors.info,
          })}
          px="0"
          onClick={toggleExpand}
        >
          See more
        </Button>
      )}
    </>
  );
};
