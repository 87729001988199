import { Box } from '@mentimeter/ragnar-ui';
import type React from 'react';
import type { ReactNode } from 'react';

export function CanvasLayout({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode;
}) {
  return (
    <Box width="100%" flex="1 1 auto" onClick={onClick}>
      {children}
    </Box>
  );
}
