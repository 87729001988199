// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostCommentsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The question admin key on which the comment is made */
  questionAdminKey: string;
  /** The styled text */
  styledText: string;
  /** The parent comment id */
  parentId: string | null;
}

export function postCommentsRequest(
  requestParams: PostCommentsRequest,
): Request {
  const data = {
    question_admin_key: toSnakeKeys(requestParams.questionAdminKey),
    styled_text: toSnakeKeys(requestParams.styledText),
    parent_id: toSnakeKeys(requestParams.parentId),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/comments`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
