import { type PropsWithChildren, useRef, useState, useEffect } from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { alpha, blackAlpha100, blackAlpha400 } from '@mentimeter/ragnar-colors';

interface OverviewListItemThumbnailScalingProps {
  isSkipped?: boolean;
  bgColor: string;
  textColor: string;
  avatars?: React.ReactNode;
  isDraggingGroup?: boolean | undefined;
  isActive?: boolean;
  isSelected?: boolean;
}

export const OverviewListItemThumbnailScaling = ({
  isSkipped,
  bgColor,
  textColor,
  children,
  avatars,
  isDraggingGroup,
  isActive = false,
  isSelected = false,
}: PropsWithChildren<OverviewListItemThumbnailScalingProps>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [scale, setScale] = useState(0);

  useEffect(() => {
    const element = ref.current;

    if (!element || !window.ResizeObserver) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const contentRect = entries[0]?.contentRect;
      if (contentRect) {
        setScale(contentRect.width / 1920);
      }
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);

  return (
    <Box
      alignItems="stretch"
      justifyContent="center"
      extend={() => ({
        flex: '1 0 auto',
        maxWidth: 'calc(100% - 32px)',
        maxHeight: '100%',
      })}
    >
      <Box
        borderRadius="lg"
        ref={ref}
        overflow="hidden"
        zIndex={1}
        extend={({ theme }) => ({
          cursor: 'pointer',
          position: 'relative',
          '&:before': {
            paddingTop: '56.25%',
            display: 'block',
            content: '""',
          },
          '&:after': {
            borderRadius: `${theme.kosmosBorderRadius.lg}px`,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            boxShadow: `inset 0 0 0 ${isActive || isSelected ? '4px' : '2px'} ${
              isActive || isSelected || isSkipped
                ? theme.colors.surfaceSunken
                : 'transparent'
            }`,
            outlineWidth: ` ${isActive || isSelected ? '2px' : '1px'} `,
            outlineStyle: `${isSkipped ? 'dashed' : 'solid'}`,
            outlineColor: `${
              isActive || isSelected
                ? theme.colors.secondary
                : isSkipped
                  ? theme.colors.borderStrong
                  : blackAlpha100
            }`,
            outlineOffset: `${isActive || isSelected ? '-2px' : '-1px'}`,
            pointerEvents: 'none',
          },
          '&:hover:after': {
            outlineColor: `${!(isActive || isSelected) && !isSkipped && blackAlpha400}`,
          },
          transition: 'all 0.1s ease',
          backgroundColor: isSkipped ? alpha(bgColor, 0.4) : bgColor,
          color: textColor,
        })}
        className="thumbnail"
      >
        <Box // Placement and scaling of the thumbnail representation
          extend={() => ({
            opacity: isSkipped ? 0.1 : 1,
            position: 'absolute',
            transformOrigin: 'top left',
            transform: `scale(${scale})`,
            flex: '1 0 auto',
            overflow: 'hidden',
          })}
        >
          {children}
        </Box>
        {avatars && (
          <Box position="absolute" bottom="5px" right="5px">
            {avatars}
          </Box>
        )}
      </Box>
      {isDraggingGroup && (
        <Box
          borderRadius={1}
          position="absolute"
          left="0"
          bottom="95%"
          overflow="hidden"
          extend={({ theme }) => ({
            border: `2px ${isSkipped ? 'dashed' : 'solid'} ${
              theme.colors.border
            }`,

            flex: '1 0 auto',
            cursor: 'pointer',
            position: 'relative',
            '&:before': {
              display: 'block',
              content: '""',
            },
            scale: 0.98,
            transform: 'rotate(-7deg)',
            transition: 'all 0.1s ease',
            backgroundColor: bgColor,
            color: textColor,
          })}
          className="thumbnail-draggable"
        />
      )}
    </Box>
  );
};
