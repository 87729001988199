import React, { forwardRef } from 'react';
import { useOrganizationMembersPaginated } from '@mentimeter/workspace-data-hooks';
import { MentionList } from '../components/MentionList';
import type {
  KeyDownHandler,
  MentionSuggestion,
  MentionsListDataHandlerProps,
} from './types';

export const MentionListWorkspaceDataHandler = forwardRef<
  KeyDownHandler,
  MentionsListDataHandlerProps
>(({ query, command }, ref) => {
  const queryString = `?&search=${encodeURIComponent(query)}&order_by=${encodeURIComponent('user_name')}&order=${encodeURIComponent('asc')}&filter=${encodeURIComponent('status:active roles:owner:admin:user')}`;

  const { members, error, isLoading } =
    useOrganizationMembersPaginated(queryString);

  const items: MentionSuggestion[] = members.map((member) => ({
    id: member.id,
    name: member.name ?? '',
    email: member.email,
    profilePictureUrl: member.profile_picture_url,
    access: 'edit',
  }));

  return (
    <MentionList
      command={command}
      items={items}
      isLoading={isLoading}
      isError={Boolean(error)}
      ref={ref}
    />
  );
});
