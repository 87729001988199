import { TrackingContext } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import type { SidebarPaneTitle } from './types';

export const trackChangedSidebarTab = (
  tab: SidebarPaneTitle | 'Closed sidebar',
  isPresentationOwner: boolean,
  isSharedPresentation: boolean,
  activeTeamMembers: number,
  currentPlan: string,
  roleInWorkspace: string,
  context: TrackingContext = TrackingContext.Editview,
) => {
  trackUser({
    event: 'Changed slide tab',
    properties: {
      tab,
      context,
      'presentation owner': isPresentationOwner,
      'shared presentation': isSharedPresentation,
      'active team members': activeTeamMembers,
      'current plan': currentPlan,
      'role in workspace': roleInWorkspace,
    },
  });
};
