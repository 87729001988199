import * as React from 'react';
import { useEffect, useState } from 'react';
import { TooltipGeneric, Prompt, P } from '@mentimeter/ragnar-ui';

export function DisabledPresentTooltip({
  presenterName,
  referenceId,
  showArrow = true,
}: {
  showArrow?: boolean | undefined;
  presenterName?: string | undefined;
  referenceId: string;
}) {
  const [presenterNameShown, setPresenterNameShown] = useState(
    presenterName || 'Someone else',
  );

  useEffect(() => {
    // This is to prevent presenterName being undefined propagating to the other users (when they have the popover open)
    // when presenter is ending their presentation
    if (presenterName) {
      setPresenterNameShown(presenterName);
    }
  }, [presenterName]);

  const content = (
    <>
      <P mt="2">
        Starting this presentation now might interrupt {presenterName} and
        affect voting results.
      </P>
      <P>
        If you’d also like to present now, you can duplicate this presentation.
      </P>
    </>
  );

  return (
    <TooltipGeneric
      showArrow={showArrow}
      referenceId={referenceId}
      placement="right"
    >
      <Prompt
        width="300px"
        p="space4"
        theme="dark"
        title={`${presenterNameShown} is currently presenting`}
      >
        {content}
      </Prompt>
    </TooltipGeneric>
  );
}
