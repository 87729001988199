import { Box } from '@mentimeter/ragnar-ui';
import { useDeviceChecker } from '@mentimeter/ragnar-device';
import { useRagnar } from '@mentimeter/ragnar-react';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { blackAlpha100 } from '@mentimeter/ragnar-colors';
import { OVERVIEW_SLIDE_WIDTH } from './constants';
import { OverviewToggleButton } from './OverviewToggleButton';

const containerHide = () => ({
  '0%': {
    visibility: 'visible',
  },
  '100%': {
    visibility: 'hidden',
  },
});

const overviewExtend: Extend = ({ theme }) => ({
  '&.hiddenoverview': {
    transform: `translateX(-${OVERVIEW_SLIDE_WIDTH}px)`,
  },
  '@media (prefers-reduced-motion: no-preference)': {
    transition: `transform ${theme.durations[1]}s ease`,
  },
});

interface Props {
  amountOfSlides: number;
  activeSlideIndex: number;
  isOverviewVisible: boolean;
  onOverviewVisibleToggle: () => void;
  children: React.ReactNode;
}

export const EditorOverviewWrapper = ({
  amountOfSlides,
  activeSlideIndex,
  isOverviewVisible,
  onOverviewVisibleToggle,
  children,
}: Props) => {
  const { isSmallDevice: mobile } = useDeviceChecker();
  const { renderer } = useRagnar();

  return (
    <Box
      as="section"
      aria-label="Slide overview"
      id="editor-overview"
      position={mobile ? 'absolute' : 'relative'}
      className={!mobile || isOverviewVisible ? undefined : 'hiddenoverview'}
      left={0}
      height="100%"
      zIndex={[40, null, 'auto']}
      overflow="visible"
      flexDirection="row-reverse"
      extend={overviewExtend}
    >
      {mobile && (
        <Box position="absolute" top={0} right={0}>
          <OverviewToggleButton
            controls="editor-overview-container"
            expanded={isOverviewVisible}
            label={`Slide overview (slide ${
              activeSlideIndex + 1
            } of ${amountOfSlides})`}
            onClick={onOverviewVisibleToggle}
          >
            {activeSlideIndex + 1} / {amountOfSlides}
          </OverviewToggleButton>
        </Box>
      )}
      <Box
        id="editor-overview-container"
        className={!mobile || isOverviewVisible ? undefined : 'hiddenoverview'}
        bg={mobile ? 'bg' : 'none'}
        height="100%"
        width={OVERVIEW_SLIDE_WIDTH}
        extend={({ theme }) => ({
          userSelect: 'none',
          borderRight:
            mobile && isOverviewVisible ? `1px solid ${blackAlpha100}` : 'none',
          '@media (prefers-reduced-motion: no-preference)': {
            '&.hiddenoverview': {
              animation: renderer
                ? `${renderer.renderKeyframe(containerHide, {})} 0s ${
                    theme.durations[1]
                  }s forwards`
                : undefined,
            },
          },
          '@media (prefers-reduced-motion: reduce)': {
            '&.hiddenoverview': {
              visibility: 'hidden',
            },
          },
        })}
      >
        {children}
      </Box>
    </Box>
  );
};
