import React, { useCallback, useMemo } from 'react';
import type { Theme } from '@mentimeter/http-clients';
import { Icon } from '@mentimeter/question-modules-importer';
import { Box, Text, TooltipGeneric } from '@mentimeter/ragnar-ui';
import {
  Thumbnail as SfinxThumbnail,
  ThumbnailProvider,
} from '@mentimeter/sfinx';
import { StringParser } from '@mentimeter/string-parser';
import { getQuestionLayout } from '@mentimeter/editor-essentials/calculations';
import { updateEditorSlideProxy } from '@mentimeter/compatibility/editor';
import { TextMarkupRenderer } from '@mentimeter/editor-ui';
import type { QuestionWithSlide } from '@mentimeter/editor-schema/api-types-overrides';
import { contrastColor } from '@mentimeter/ragnar-colors';
import type { ThumbnailItem } from '../../../sfinx/src/setup/Thumbnail';
import { getThumbnailType } from './overviewListItemHelpers';

const THUMBNAIL_FONT_SIZE = 140;
const THUMBNAIL_FONT_SIZE_MOBILE = 120;

const MemoizedStringParser = React.memo(
  ({
    source,
    disableLineBreaks,
  }: Pick<
    React.ComponentProps<typeof StringParser>,
    'source' | 'disableLineBreaks'
  >) => (
    <StringParser
      source={source}
      disableLineBreaks={Boolean(disableLineBreaks)}
    />
  ),
);

export type OverviewListItemType = ThumbnailItem &
  Pick<
    QuestionWithSlide,
    | 'has_comments'
    | 'skip_slide'
    | 'question_styled'
    | 'question_description_styled'
    | 'question_description'
    | 'module_id'
    | 'question_image_url'
    | 'hasComments'
  >;

interface OverviewListItemThumbnailProps {
  isMobile: boolean;
  theme: Theme;
  listItem: OverviewListItemType;
}

export const OverviewListItemThumbnail = ({
  isMobile,
  theme,
  listItem,
}: OverviewListItemThumbnailProps) => {
  const question = useMemo(
    () =>
      updateEditorSlideProxy(listItem, {
        layout: { $set: getQuestionLayout(listItem) },
        question_styled: { $set: listItem.question_styled ?? {} },
        question_description_styled: {
          $set: listItem.question_description_styled ?? {},
        },
      }),
    [listItem],
  );

  const thumbnailType = getThumbnailType(
    question.image?.presets.small.url,
    question.slide_type,
    question.type,
  );

  const thumbnailStyling = useCallback(
    () => ({
      padding: thumbnailType.icon ? 0 : '0 36px',
    }),
    [thumbnailType.icon],
  );

  const titleId = `slide-thumbnail-title-${question.id}`;

  if (!thumbnailType.content) {
    return null;
  }

  const title =
    question.slide_type === 'free-text'
      ? question.question_description
      : question.question;

  const styledTitle =
    question.slide_type === 'free-text'
      ? question.question_description_styled
      : question.question_styled;

  const bgColor =
    question.theme_settings?.background_color || theme.background_color;
  const contrastTextColor = contrastColor(bgColor);

  return (
    <>
      <ThumbnailProvider
        config={{
          mode: 'idle',
          renderSlideControls: false,
          enableAnimations: false,
          enableHotkeys: false,
          baseUrl: '',
          votingUrl: '',
          renderApiUrl: '',
        }}
        presentationTheme={theme}
        thumbnailItem={question}
      >
        <SfinxThumbnail extend={thumbnailStyling} thumbnailItem={question}>
          <Box
            width="inherit"
            height="inherit"
            alignItems="inherit"
            justifyContent="inherit"
            extend={() => ({ gap: '3%' })}
          >
            <Box flex="0 1 auto" aria-hidden>
              {thumbnailType.icon && (
                <Icon
                  moduleId={question.module_id}
                  textColor={
                    question.override_theme &&
                    question.theme_settings?.text_color
                      ? question.theme_settings.text_color
                      : theme.text_color
                  }
                  size={400}
                />
              )}
            </Box>
            <Box flex="0 0 auto" maxWidth="100%">
              <Text
                id={titleId}
                truncate
                color={contrastTextColor}
                fontSize={
                  isMobile ? THUMBNAIL_FONT_SIZE_MOBILE : THUMBNAIL_FONT_SIZE
                }
                extend={() => ({ pointerEvents: 'auto' })}
              >
                <MemoizedStringParser source={title} disableLineBreaks />
              </Text>
            </Box>
          </Box>
        </SfinxThumbnail>
      </ThumbnailProvider>
      <TooltipGeneric
        referenceId={titleId}
        bg="highContrast"
        placement="right"
        delay={150}
        ignorePointer
      >
        <Box width="200px">
          <Box
            maxWidth="200px"
            id="tooltipbox"
            borderRadius="xl"
            bg="highContrast"
            py="space2"
            px="space3"
            maxHeight="56px"
            overflow="hidden"
          >
            <Text
              color="onHighContrast"
              fontSize="87.5"
              extend={({ theme }) => ({
                // force all tiptap text to use readable color
                '& *': { color: `${theme.colors.onHighContrast} !important` },
              })}
            >
              {Object.keys(styledTitle).length === 0 ? (
                title
              ) : (
                <TextMarkupRenderer content={styledTitle} />
              )}
            </Text>
            <Box
              height="10px"
              position="absolute"
              width="100%"
              right="0"
              bottom="0"
              bg="highContrast"
              zIndex={1}
            />
          </Box>
        </Box>
      </TooltipGeneric>
    </>
  );
};
