import { ReactRenderer } from '@tiptap/react';
import type {
  SuggestionProps,
  SuggestionKeyDownProps,
} from '@tiptap/suggestion';
import type { RefAttributes } from 'react';
import { MentionsDataHandler } from '../data-handlers/MentionsDataHandler';
import type {
  KeyDownHandler,
  MentionsListDataHandlerProps,
  MentionSuggestion,
  SelectedSuggestionItem,
} from '../data-handlers/types';

type PropsType = SuggestionProps<MentionSuggestion, SelectedSuggestionItem>;

export type ReactRendererComponentType = ReactRenderer<
  KeyDownHandler,
  MentionsListDataHandlerProps & RefAttributes<KeyDownHandler>
>;

export const createSuggestion = (
  showPopover: (
    component: ReactRendererComponentType,
    clientRect: DOMRect | null,
  ) => void,
  hidePopover: () => void,
  trackMentionIntent: () => void,
) => {
  return {
    render: () => {
      let component: ReactRendererComponentType;

      return {
        onStart: (props: PropsType) => {
          component = new ReactRenderer(MentionsDataHandler, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          trackMentionIntent();
          showPopover(component, props.clientRect());
        },

        onUpdate(props: PropsType) {
          component?.updateProps(props);
        },

        onKeyDown(props: SuggestionKeyDownProps) {
          if (props.event.key === 'Escape') {
            hidePopover();

            return true;
          }

          if (!component?.ref?.onKeyDown) {
            return false;
          }

          return component?.ref?.onKeyDown(props);
        },

        onExit() {
          component?.destroy();
          hidePopover();
        },
      };
    },
  };
};
