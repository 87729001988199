import type { SidebarPaneId } from '@mentimeter/editor-essentials/types';
import { useCallback } from 'react';
import { SIDEBAR_PANES, SIDEBAR_TABS } from './SidebarComponents';
import type { SidebarPanes, SidebarTabs } from './types';

export const getSidebarDetails = () => {
  const tabs: SidebarTabs = SIDEBAR_TABS;

  const firstSurfaceTabs = ['edit', 'comments'];

  const sidebarTabSurfaces = [
    tabs.filter((t) => firstSurfaceTabs.includes(t)) as SidebarTabs,
    tabs.filter((t) => !firstSurfaceTabs.includes(t)) as SidebarTabs,
  ];

  return {
    sidebarPanes: SIDEBAR_PANES,
    sidebarTabSurfaces,
  };
};

export const isValidPaneId = (
  pane: string,
  sidebarPanes: SidebarPanes,
): pane is SidebarPaneId => {
  return pane in sidebarPanes;
};

export const isSidebarPaneOpen = (
  activePane: SidebarPaneId,
  sidebarPanes: SidebarPanes,
) =>
  activePane !== 'initial' &&
  activePane !== 'closed' &&
  isValidPaneId(activePane, sidebarPanes);

export const useHandleSidebarTabClick = (
  setActivePane: (paneId: SidebarPaneId) => void,
  sidebarPanes: SidebarPanes,
) => {
  return useCallback(
    (dismissable: boolean, active: boolean, id: string) => {
      if (active && dismissable) {
        setActivePane('closed');
      } else if (isValidPaneId(id, sidebarPanes)) {
        setActivePane(id);
      }
    },
    [setActivePane, sidebarPanes],
  );
};

export const useHandleSetActiveSidebarTab = (
  setActivePane: (paneId: SidebarPaneId) => void,
  sidebarPanes: SidebarPanes,
) => {
  return useCallback(
    (activeTabId: string) => {
      if (isValidPaneId(activeTabId, sidebarPanes)) {
        setActivePane(activeTabId as keyof typeof sidebarPanes);
      }
    },
    [setActivePane, sidebarPanes],
  );
};
