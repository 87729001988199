import { Node, mergeAttributes } from '@tiptap/core';

export interface SmallOptions {
  /**
   * The HTML attributes for a small node.
   * @default {}
   * @example { class: 'foo' }
   */
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    small: {
      /**
       * Toggle small
       * @example editor.commands.setSmall()
       */
      setSmall: () => ReturnType;
    };
  }
}

export const Small = Node.create<SmallOptions>({
  name: 'small',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',

  content: 'inline*',

  parseHTML() {
    return [
      {
        tag: 'small',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'small',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setSmall:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name);
        },
    };
  },

  // Future improvement: add keymap to toggle small
});
