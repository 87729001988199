import { EditPaneIcon } from '@mentimeter/editor-properties-pane';
import { BadgeWrap, Box, Text } from '@mentimeter/ragnar-ui';
import {
  CommentSolidIcon,
  DesignSolidIcon,
  InteractionSolidIcon,
} from '@mentimeter/ragnar-visuals';
import type { InteractiveCanvasSidebarPaneId, SidebarPane } from './types';

const commentsPane: SidebarPane = {
  title: 'Comments',
  getIcon: ({ commentsCount }: { commentsCount: number }) =>
    commentsCount > 0 ? (
      <BadgeWrap
        placement={2}
        badge={
          <Box
            bg="infoWeak"
            borderRadius="full"
            height="17px"
            width="17px"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="62.5" fontWeight="semiBold">
              {commentsCount}
            </Text>
          </Box>
        }
      >
        <CommentSolidIcon aria-hidden={true} size={4} color="inherit" />
      </BadgeWrap>
    ) : (
      <CommentSolidIcon aria-hidden={true} size={4} color="inherit" />
    ),
};

export const SIDEBAR_PANES: Record<
  InteractiveCanvasSidebarPaneId,
  SidebarPane
> = {
  edit: {
    title: 'Edit',
    getIcon: ({ canvasArea, slideType, isMobile }) => (
      <EditPaneIcon
        canvasArea={isMobile ? 'slide' : canvasArea}
        slideType={isMobile ? 'choices' : slideType}
      />
    ),
  },
  comments: commentsPane,
  interactivity: {
    title: 'Interactivity',
    icon: <InteractionSolidIcon aria-hidden={true} size={4} color="inherit" />,
  },
  themes: {
    title: 'Themes',
    icon: <DesignSolidIcon aria-hidden={true} size={4} color="inherit" />,
  },
};

export const SIDEBAR_TABS = Object.keys(
  SIDEBAR_PANES,
) as (keyof typeof SIDEBAR_PANES)[];
