import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { getNameInitials } from '@mentimeter/user';
import {
  Avatar,
  Box,
  Text,
  Clickable,
  TextEllipsis,
  type TextEllipsisT,
  Tooltip,
} from '@mentimeter/ragnar-ui';
import type {
  KeyDownHandler,
  MentionPopOver,
  MentionSuggestion,
  SelectedSuggestionItem,
} from '../data-handlers/types';

export const MAX_ITEMS = 10;
export const MAX_WIDTH = '350px';

const EmptySuggestion = ({ text }: { text: string }) => (
  <Text fontSize="87.5" px="space3" py="space4" color="textWeaker">
    {text}
  </Text>
);

export const ItemAvatar = ({
  item,
  size = 'compact',
}: { item: MentionPopOver; size?: 'compact' | 'large' }) => (
  <Avatar
    size={size}
    initials={getNameInitials(item.name || '', true).toUpperCase()}
    name={item.name || ''}
    itemId={item.id}
    type="user"
    profilePictureUrl={item.profilePictureUrl}
  />
);

export const ItemEllipsedText = ({
  text,
  ...rest
}: {
  text: string;
} & TextEllipsisT) => (
  <TextEllipsis text={text} fontSize="87.5" color="text" {...rest} />
);

export const MentionListItem = ({
  item,
  disabled,
  toolTipRef,
}: { item: MentionSuggestion; disabled: boolean; toolTipRef: string }) => {
  const userName = item.name || item.email;
  return (
    <Box width="100%" alignItems="center" flexDirection="row" px="space2">
      <ItemAvatar item={item} />
      <Box ml={2} flex="1 1 auto" my="auto">
        <ItemEllipsedText
          id={`suggestion-name-${item.id}`}
          text={userName}
          tooltipDescription={item.name || item.email}
          color={disabled ? 'textWeaker' : 'text'}
        />
      </Box>
      {disabled && (
        <Tooltip
          referenceId={toolTipRef}
          description={`${userName} has view-only access. Change to editor to mention.`}
          placement="left"
        />
      )}
    </Box>
  );
};

interface MentionListProps {
  items: MentionSuggestion[];
  isLoading: boolean;
  isError: boolean;
  command: (item: SelectedSuggestionItem) => void;
}

export const MentionList = forwardRef<KeyDownHandler, MentionListProps>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      const item = props.items[index];

      if (item && item.access !== 'view') {
        props.command({ id: item.id, label: item.name ?? item.email });
      }
    };

    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length,
      );
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    const escapeHandler = () => {
      setSelectedIndex(-1);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          escapeHandler();
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    if (props.isLoading) return <EmptySuggestion text="Loading..." />;
    if (props.isError)
      return <EmptySuggestion text="Error loading collaborators" />;
    if (!props.items.length)
      return <EmptySuggestion text="No suggestion found" />;

    if (selectedIndex === -1) return null;

    return (
      <Box bg="bg" p="space2" maxWidth="265px">
        {props.items.slice(0, MAX_ITEMS).map((item, index) => {
          const disabled = item.access === 'view';
          const toolTipRef = `mention-list-item-${item.id}`;
          return (
            <Clickable
              id={toolTipRef}
              width="100%"
              py="space2"
              bg={index === selectedIndex ? 'primaryWeakest' : 'bg'}
              key={item.id}
              onClick={() => selectItem(index)}
              disabled={disabled}
            >
              <MentionListItem
                item={item}
                disabled={disabled}
                toolTipRef={toolTipRef}
              />
            </Clickable>
          );
        })}
      </Box>
    );
  },
);
