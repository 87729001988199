import { useEffect } from 'react';
import {
  usePathname,
  useRouter,
  useSearchParams,
} from '@mentimeter/next-navigation';

const QUERY_PARAMS_TO_REMOVE = [
  'new',
  'first',
  'template-modal',
  'quick-create',
  'import-modal',
  'source',
];

export function useClearInitialQueryParams() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const path = usePathname();
  useEffect(() => {
    if (!searchParams) return;
    if (!hasQueryToRemove(searchParams)) return;

    const newSearchParams = new URLSearchParams();
    for (const [key, value] of searchParams) {
      if (!QUERY_PARAMS_TO_REMOVE.includes(key)) {
        newSearchParams.append(key, value);
      }
    }

    let newPath = path;
    const newSearchParamsStr = newSearchParams.toString();
    if (newSearchParamsStr.length > 0) {
      newPath += `?${newSearchParamsStr}`;
    }

    router.replace(newPath);
  }, [path, searchParams, router]);
}

function hasQueryToRemove(searchParams: URLSearchParams) {
  for (const key of QUERY_PARAMS_TO_REMOVE) {
    if (searchParams.has(key)) return true;
  }
  return false;
}
