import React, { forwardRef } from 'react';
import { useEditorSeries } from '@mentimeter/editor-data-hooks';
import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { MentionListWorkspaceDataHandler } from './MentionListWorkspaceDataHandler';
import { MentionListDataHandler } from './MentionListDataHandler';
import type { KeyDownHandler, MentionsListDataHandlerProps } from './types';

export const MentionsDataHandler = forwardRef<
  KeyDownHandler,
  MentionsListDataHandlerProps
>((props, ref) => {
  const seriesId = useActiveSeriesId();
  const { series } = useEditorSeries(seriesId);

  const isWorkspacePresentation = series?.workspace_id !== null;

  if (isWorkspacePresentation)
    return <MentionListWorkspaceDataHandler {...props} ref={ref} />;

  return <MentionListDataHandler {...props} ref={ref} />;
});
