/* eslint-disable menti-react/forbidden-filename */
import type { Question } from '@mentimeter/http-clients';

export interface ThumbnailTypeT {
  icon: boolean;
  compact: boolean;
  content: boolean;
}

export const DEFAULT_THUMBNAIL_TYPE: ThumbnailTypeT = {
  icon: true,
  compact: false,
  content: true,
};

export const THUMBNAIL_TYPE_MAP: {
  placeholder: ThumbnailTypeT;
  document: ThumbnailTypeT;
} = {
  placeholder: { icon: false, compact: false, content: false },
  document: { icon: false, compact: false, content: true },
};

export const getThumbnailType = (
  thumbnailImageUrl: string | undefined,
  slideType: Question['slide_type'] | undefined,
  type: Question['type'] | undefined,
): ThumbnailTypeT => {
  if (
    type === 'slide' &&
    (slideType === 'document' || slideType === 'image') &&
    thumbnailImageUrl
  )
    return THUMBNAIL_TYPE_MAP.document;
  return (
    (type
      ? THUMBNAIL_TYPE_MAP[type as keyof typeof THUMBNAIL_TYPE_MAP]
      : undefined) ?? DEFAULT_THUMBNAIL_TYPE
  );
};
