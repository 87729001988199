import type { Theme } from '@mentimeter/http-clients';
import { Box, DropdownMenuTrigger } from '@mentimeter/ragnar-ui';
import React from 'react';
import type {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from '@dnd-kit/core';
import { useIsSlideDeckMigrated } from '@mentimeter/compatibility/migration-status';
import { MigratedSlideIndicator } from './MigratedSlideIndicator';
import { OverviewListItem } from './OverviewListItem';
import { OverviewListItemCommentIcon } from './OverviewListItemCommentIcon';
import { OverviewListItemControls } from './OverviewListItemControls';
import { OverviewListItemDropdownMenuIcon } from './OverviewListItemDropdownMenuIcon';
import { OverviewListItemIndex } from './OverviewListItemIndex';
import { OverviewListItemPlayButton } from './OverviewListItemPlayButton';
import {
  OverviewListItemThumbnail,
  type OverviewListItemType,
} from './OverviewListItemThumbnail';
import { OverviewListItemThumbnailScaling } from './OverviewListItemThumbnailScaling';
import { OVERVIEW_SLIDE_WIDTH } from './constants';

export interface OverviewListItemWrapperProps {
  active: boolean;
  index: number;
  isDragging?: boolean;
  isDraggedElement?: boolean;
  isSomeonePresenting?: boolean | undefined;
  item: OverviewListItemType;
  mobile: boolean;
  presenterName?: string | undefined;
  selected: boolean;
  theme: Theme;
  renderAvatars?: (questionId: string) => React.ReactElement | null;
  onClick: (event: React.MouseEvent<HTMLElement>, itemId: string) => void;
  onClickCommentIcon: () => void;
  onStartPresenting?: (() => void) | undefined;
  onUnskip?: (questionId: string) => void;
  onDropdownMenuOpen?: (questionId: string) => void;
  showPlayButton?: boolean | undefined;
  setNodeRef?: (element: HTMLElement | null) => void;
  listeners?: DraggableSyntheticListeners | undefined;
  style?: React.CSSProperties;
  attributes?: DraggableAttributes;
  userIsViewCollaborator?: boolean;
}

const OverviewListItemWrapper = ({
  active,
  index,
  isDragging,
  isDraggedElement,
  isSomeonePresenting,
  item,
  mobile,
  presenterName,
  selected,
  renderAvatars,
  theme,
  onClick,
  onClickCommentIcon,
  onStartPresenting,
  onUnskip,
  onDropdownMenuOpen,
  showPlayButton,
  userIsViewCollaborator,
  ...rest
}: OverviewListItemWrapperProps) => {
  const isMigrated = useIsSlideDeckMigrated();
  const hasComments = isMigrated ? item.hasComments : item.has_comments;
  const showCommentIcon = userIsViewCollaborator !== false && hasComments;
  return (
    <OverviewListItem
      slideId={item.id}
      isDragging={isDragging}
      isDraggedElement={isDraggedElement}
      isSelected={selected}
      onClick={(event) => onClick(event, item.id)}
      {...rest}
    >
      <Box
        pr={3}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="stretch"
        id={`slide-thumbnail-${item.id}`}
        data-testid={`slide-thumbnail-${index}`}
        width={OVERVIEW_SLIDE_WIDTH}
        maxWidth="100%"
        data-migrated={isMigrated || undefined}
      >
        <OverviewListItemControls isActive={active}>
          {showPlayButton && onStartPresenting && !mobile ? (
            <OverviewListItemPlayButton
              questionId={item.id}
              presenterName={presenterName}
              isSomeonePresenting={isSomeonePresenting}
              onStartPresenting={onStartPresenting}
              mt={2}
            />
          ) : (
            <Box />
          )}

          {showCommentIcon && (
            <OverviewListItemCommentIcon
              itemId={item.id}
              onClick={onClickCommentIcon}
            />
          )}

          <OverviewListItemIndex
            isMobile={mobile}
            isSkipped={item.skip_slide}
            index={index}
            listItemId={item.id}
            onUnskipClick={onUnskip}
          />
          <Box alignItems="center" aria-hidden>
            {onDropdownMenuOpen && (
              <DropdownMenuTrigger
                onPointerDown={() => {
                  onDropdownMenuOpen(item.id);
                }}
              >
                <OverviewListItemDropdownMenuIcon
                  id={item.id}
                  mobile={mobile}
                  slideIsSelected={selected}
                />
              </DropdownMenuTrigger>
            )}
          </Box>
        </OverviewListItemControls>
        <OverviewListItemThumbnailScaling
          isSkipped={item.skip_slide}
          bgColor={theme.background_color}
          textColor={theme.text_color}
          avatars={renderAvatars?.(item.id)}
          isActive={active}
          isSelected={selected}
        >
          <OverviewListItemThumbnail
            isMobile={mobile}
            theme={theme}
            listItem={item}
          />
        </OverviewListItemThumbnailScaling>
        <MigratedSlideIndicator isMigrated={isMigrated} />
      </Box>
    </OverviewListItem>
  );
};

const MemoOverviewListItemWrapper = React.memo(OverviewListItemWrapper);

export { MemoOverviewListItemWrapper as OverviewListItemWrapper };
