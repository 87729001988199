import React from 'react';
import { TabbedInterfaceContext } from './context';

export const useTabbedInterface = () => {
  const context = React.useContext(TabbedInterfaceContext);

  if (context === undefined) {
    throw new Error('useTabbedInterface must be used within a TabbedInterface');
  }
  return context;
};
