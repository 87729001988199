import {
  CANVAS_SUPPORTED_BLOCK_MARKUP,
  CANVAS_SUPPORTED_COLOR,
  CANVAS_SUPPORTED_INLINE_MARKUP,
  DEFAULT_EXTENSIONS,
} from '@mentimeter/tiptap';
import {
  isEmptyObject,
  EditorContent as TiptapEditorContent,
  useEditor as useTiptapEditor,
} from '@tiptap/react';
import { useEffect } from 'react';

interface TextMarkupRendererProps {
  content: Record<string, unknown> | string;
}

export const TextMarkupRenderer = ({ content }: TextMarkupRendererProps) => {
  const editor = useTiptapEditor({
    extensions: [
      ...DEFAULT_EXTENSIONS,
      ...CANVAS_SUPPORTED_COLOR,
      ...CANVAS_SUPPORTED_INLINE_MARKUP,
      ...CANVAS_SUPPORTED_BLOCK_MARKUP,
    ],
    content: isEmptyObject(content) ? '' : content,
    editable: false,
    immediatelyRender: false,
  });

  useEffect(() => {
    // TipTap warns if we try to set an empty object as content
    if (!isEmptyObject(content)) {
      editor?.commands.setContent(content);
    }
  }, [content, editor]);

  return <TiptapEditorContent editor={editor} />;
};
