import {
  getCommentsRequest,
  getCommentsResponse,
  type GetCommentsResponse,
} from '@core-api/comments/comments';
import type { Comment } from '@core-api/comments/types/response';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import userCache from '@mentimeter/user';
import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';

const getCacheKey = (questionAdminKey: string) =>
  `comments/?questionAdminKey=${questionAdminKey}`;

export const revalidateComments = (questionAdminKey: string) =>
  mutate(getCacheKey(questionAdminKey));

export const useComments = (
  questionAdminKey: string | undefined,
): {
  comments: Comment[];
  activeComments: Comment[];
  isLoading: boolean;
  error: boolean;
} => {
  const { data, error, isLoading } = useSWR<GetCommentsResponse>(
    questionAdminKey ? getCacheKey(questionAdminKey) : null,
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();

      const response = await fetch(
        getCommentsRequest({
          questionAdminKey: questionAdminKey as string,
          region,
          userAuth: sessionToken,
        }),
      );
      if (!response.ok) {
        const err = new MentiError('error listing comments', {
          feature: 'comments-pane-editor',
          cause: error,
        });
        captureException(err);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return getCommentsResponse(response);
    },
    {
      revalidateOnFocus: false,
      dedupingInterval: 30000,
    },
  );

  const comments = useMemo(() => data?.data || [], [data]);

  const activeComments = useMemo(
    () =>
      comments.filter(
        (comment) => comment.parentId === null && comment.resolvedAt === null,
      ),
    [comments],
  );

  return {
    comments,
    activeComments,
    isLoading,
    error,
  };
};
