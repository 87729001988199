import type { IRenderer } from '@mentimeter/ragnar-react';

export const slideUpAnimation = (show: boolean) => ({
  transition: 'all 0.3s ease-in-out',
  transform: show ? 'translateY(0)' : 'translateY(20px)',
});

export const newCommentSlideInAnimation = (
  renderer: IRenderer,
  isReply: boolean,
) => {
  const style = isReply
    ? {
        '0%': { transform: 'translateY(-100%)', opacity: 0 },
        '100%': { transform: 'translateY(0)', opacity: 1 },
      }
    : {
        '0%': { transform: 'translateY(100%)', opacity: 0 },
        '100%': { transform: 'translateY(0)', opacity: 1 },
      };

  const slideInKeyframe =
    renderer.renderKeyframe && renderer.renderKeyframe(() => style, {});

  return {
    animationDuration: '0.5s',
    animationIterationCount: 1,
    animationName: slideInKeyframe,
    zIndex: 1,
    overflow: 'hidden',
  };
};
