import { useMatch } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';
import {
  SIDEBAR_BAR_HEIGHT_MOBILE,
  SIDEBAR_BAR_TABLET_WIDTH,
  SIDEBAR_BAR_WIDTH,
} from '../../constants';

interface BarProps {
  children: ReactNode;
}

export const Bar = ({ children }: BarProps) => {
  const isMobile = useMatch({ lessThan: 2 });

  return (
    <Box
      position="static"
      bg={isMobile ? 'bg' : 'transparent'}
      pr="space4"
      pl={['space2', 'space0']}
      pt={['space2', 'space4', null]}
      pb={['space2', 'space4', null]}
      alignItems="stretch"
      flexDirection={['row', 'column', null]}
      width={['100%', SIDEBAR_BAR_TABLET_WIDTH, SIDEBAR_BAR_WIDTH]}
      height={[SIDEBAR_BAR_HEIGHT_MOBILE, 'auto', null]}
      borderTopWidth={isMobile ? 1 : 0}
      borderStyle="solid"
      borderColor="borderWeak"
      extend={() => ({
        overflowX: isMobile ? 'auto' : undefined,
        overflowY: isMobile ? undefined : 'auto',
      })}
    >
      {children}
    </Box>
  );
};
