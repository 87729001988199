// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PatchCommentsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The comment id */
  id: string;
  resolve?: boolean;
  /** The edited styled text */
  styledText?: string;
}

export function patchCommentsRequest(
  requestParams: PatchCommentsRequest,
): Request {
  const data = {
    resolve: toSnakeKeys(requestParams.resolve),
    styled_text: toSnakeKeys(requestParams.styledText),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/comments/${requestParams.id}`;
  return new Request(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
