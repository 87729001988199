import { Clickable } from '@mentimeter/ragnar-ui';
import { MoreIcon } from '@mentimeter/ragnar-visuals';
import { shade, whiteBase } from '@mentimeter/ragnar-colors';
import { forwardRef } from 'react';

interface Props {
  id: string;
  mobile: boolean;
  slideIsSelected: boolean;
}

export const OverviewListItemDropdownMenuIcon = forwardRef<HTMLElement, Props>(
  ({ id, mobile, slideIsSelected = false, ...rest }, ref) => (
    <Clickable
      ref={ref}
      aria-label="Open context menu"
      data-testid={`overview-actions-${id}`}
      id={`overview-actions-${id}`}
      p={1}
      extend={({ theme }) => ({
        marginTop: 'auto',
        opacity: mobile ? 1 : 0,
        transition: 'opacity 0.2s',
        '&:focus': {
          opacity: 1,
        },
        '&[aria-expanded=true]': {
          opacity: 1,
        },
        '&:hover': {
          backgroundColor: slideIsSelected
            ? shade(theme.colors.primary, whiteBase, 0.8)
            : theme.colors.neutral,
        },
      })}
      {...rest}
    >
      <MoreIcon
        size={mobile ? 2 : 0}
        color={slideIsSelected ? 'onPrimaryWeakest' : 'onNeutral'}
      />
    </Clickable>
  ),
);
