import { Box } from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';

export function MainLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Box bg="surfaceSunken" height="100%" width="100%" flex="1 1 auto">
      {children}
    </Box>
  );
}
