export const getCommentLink = (commentId: string, resolved: boolean) => {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);

  queryParams.set('comment', commentId);

  if (resolved) {
    queryParams.set('resolved', 'true');
  } else {
    queryParams.delete('resolved');
  }

  url.search = queryParams.toString();

  const viewUrl = url.toString().replace(/edit/, 'view');
  return viewUrl;
};
