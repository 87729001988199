/* eslint-disable menti-react/forbidden-filename */
export const isDraggingMultiple = (
  draggingIndex: number,
  selectedQuestionIds: string[],
  items: Array<{ id: string }>,
): boolean => {
  const draggingQuestionId = items[draggingIndex]?.id;
  return (
    selectedQuestionIds?.length > 1 &&
    selectedQuestionIds.includes(draggingQuestionId || '')
  );
};

export const getIndexForMultiDrag = (
  draggingIndex: number,
  selectedQuestionIds: string[],
  items: Array<{ id: string }>,
  destinationIndex: number,
): number => {
  const draggingOutsideSelection = !selectedQuestionIds.includes(
    items[draggingIndex]?.id as string,
  );

  // Don't move a group of slides if they are not being moved "outside"
  // of themselves
  if (
    !draggingOutsideSelection &&
    selectedQuestionIds.includes(items[destinationIndex]?.id as string)
  ) {
    return draggingIndex;
  }

  // If we are moving a group of slides to a position after themselves,
  // we need to offset their own indexes
  if (draggingIndex < destinationIndex) {
    return destinationIndex - selectedQuestionIds.length + 1;
  }

  return destinationIndex;
};
