import { Box, CharacterCount, inputRule } from '@mentimeter/ragnar-ui';
import type { Editor } from '@tiptap/react';
import { EditorContent } from '@tiptap/react';
import { addUnit } from '@mentimeter/ragnar-utils';
import { COMMENT_CHAR_LIMIT } from '../data-handlers/CommentsControlDataHandler';
import { slideUpAnimation } from '../util/animations';
import { MentionSuggestionsPopOver } from './MentionSuggestionsPopOver';
import type { ReactRendererComponentType } from './suggestion';

export const RichTextEditor = ({
  showExpanded,
  editor,
  showPopover,
  position,
  suggestionContent,
  textContent,
  minHeight,
}: {
  showExpanded: boolean;
  editor: Editor;
  showPopover: boolean;
  position: DOMRect | null;
  suggestionContent: ReactRendererComponentType | undefined;
  textContent: string;
  minHeight: number | undefined;
}) => {
  return (
    <Box
      position="relative"
      mt="space4"
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      extend={({ theme }) => ({
        minHeight: showExpanded
          ? addUnit(minHeight || theme.kosmosSpacing.space20)
          : addUnit(theme.kosmosSpacing.space5),
        ...slideUpAnimation(showExpanded),
        '> div.tiptap-editor-wrapper': { display: 'flex', flex: 1 },
        '& .tiptap .is-editor-empty:first-child::before': {
          color: theme.colors.textWeaker,
          content: 'attr(data-placeholder)',
          float: 'left',
          height: 0,
          pointerEvents: 'none',
        },
        '& .tiptap-editor': {
          flex: '1 1 auto',
          ...inputRule({ theme, status: 'idle' }),
          fontSize: addUnit(theme.kosmosFontSize['87.5']),
          lineHeight: addUnit(theme.kosmosLineHeight.fixed[100]),
          padding: addUnit(theme.kosmosSpacing.space4),
        },
      })}
    >
      <EditorContent editor={editor} className="tiptap-editor-wrapper" />

      <MentionSuggestionsPopOver
        showPopover={showPopover}
        position={position}
        suggestionContent={suggestionContent}
      />
      {showExpanded && (
        <CharacterCount
          extend={() => ({
            bottom: 0,
            pointerEvents: 'none',
            'user-select': 'none',
          })}
          maxLength={COMMENT_CHAR_LIMIT}
          value={textContent}
          multiline={true}
          id="comment-character-counter"
        />
      )}
    </Box>
  );
};
