import { Box, Button } from '@mentimeter/ragnar-ui';
import { CrossIcon, LayersIcon } from '@mentimeter/ragnar-visuals';
import type { ReactNode } from 'react';

interface OverviewToggleButtonProps {
  controls: string;
  expanded: boolean;
  label: string;
  children: ReactNode;
  onClick: () => void;
}

export const OverviewToggleButton = ({
  controls,
  expanded,
  label,
  children,
  onClick,
}: OverviewToggleButtonProps) => (
  <Box
    extend={({}) => ({
      transform: 'translateX(calc(100% + 12px))',
    })}
  >
    <Button
      size="compact"
      variant="primary"
      aria-expanded={expanded}
      aria-controls={controls}
      aria-label={label}
      mt="space4"
      onClick={onClick}
      px={expanded ? 'space2' : 'space3'}
    >
      {expanded ? (
        <CrossIcon color="onPrimary" />
      ) : (
        <Box gap="space1" flexDirection="row">
          <LayersIcon color="onPrimary" />
          {children}
        </Box>
      )}
    </Button>
  </Box>
);
