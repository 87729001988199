import { create } from 'zustand';

export interface CommentT {
  id: string;
  comment: string;
  name?: string;
}

interface CommentsStore {
  current: CommentT[];
  backlog: CommentT[];
  removeFromCurrent: boolean;
  reset: () => void;
  enqueueToBacklog: (item: CommentT) => void;
  transferToCurrent: () => void;
  setRemoveFromCurrent: (removeFromCurrent: boolean) => void;
}

const MAX_COMMENTS = 4;

const enqueue = (arr: CommentT[], item: CommentT) => [...arr, item];
const dequeue = (queue: CommentT[]) =>
  queue.length > 0 ? queue.slice(1) : queue;

export const useCommentsStore = create<CommentsStore>((set) => ({
  current: [],
  backlog: [],
  // Decides if we should start removing items from visual queue
  removeFromCurrent: false,
  reset() {
    return set({
      current: [],
      backlog: [],
    });
  },
  setRemoveFromCurrent(removeFromCurrent: boolean) {
    return set(() => ({
      removeFromCurrent,
    }));
  },
  enqueueToBacklog(item: CommentT) {
    return set((state) => ({
      backlog: enqueue(state.backlog, item),
    }));
  },
  transferToCurrent() {
    return set((state) => {
      const item = state.backlog[0];
      const backlog = dequeue(state.backlog);

      const currentQueueTooLong = state.current.length > MAX_COMMENTS;
      if (item) {
        let current = state.current;
        if (currentQueueTooLong) {
          current = dequeue(current);
        }
        current = enqueue(current, item);
        return { current, backlog };
      } else if (state.removeFromCurrent || currentQueueTooLong) {
        return {
          current: dequeue(state.current),
        };
      }

      return {};
    });
  },
}));
