import type { SidebarPaneId } from '@mentimeter/editor-essentials/types';
import {
  SidebarTab,
  Surface,
  TabBar,
} from '@mentimeter/editor-properties-pane';
import type { GetIconProps, SidebarPanes, SidebarTabs } from './types';

export const InteractiveCanvasTabBar = ({
  disabled,
  disabledDescription,
  sidebarPanes,
  onClick,
  getIconProps,
  sidebarTabSurfaces,
}: {
  disabled: (tab?: SidebarPaneId) => boolean;
  disabledDescription: ({
    title,
    tab,
  }: { title?: string; tab?: SidebarPaneId }) => string | undefined;
  onClick: (dismissable: boolean, active: boolean, id: string) => void;
  getIconProps: GetIconProps;
  sidebarPanes: SidebarPanes;
  sidebarTabSurfaces: SidebarTabs[];
}) => (
  <TabBar>
    {sidebarTabSurfaces.map((surface, surfaceIndex) => {
      const isFirst = surfaceIndex === 0;
      return (
        <Surface
          borderBottomLeftRadius={isFirst ? undefined : [null, 'none']}
          borderBottomRightRadius={isFirst ? undefined : 'none'}
          borderTopRightRadius={isFirst ? undefined : ['none', '2xl']}
          key={surfaceIndex}
        >
          {surface.map((id) => {
            const sidebarPane = sidebarPanes[id as keyof typeof sidebarPanes];
            const { title, getIcon, icon } = sidebarPane;
            const tabIcon = getIcon ? getIcon(getIconProps) : icon;

            return (
              <SidebarTab
                key={id}
                id={id}
                title={title}
                icon={tabIcon}
                disabled={disabled(id)}
                disabledDescription={
                  disabled(id) ? disabledDescription({ title }) : undefined
                }
                onClick={onClick}
              />
            );
          })}
        </Surface>
      );
    })}
  </TabBar>
);
