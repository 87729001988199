import { Button } from '@mentimeter/ragnar-ui';

export const CommentsViewSwitcher = ({
  showResolvedComments,
  resolvedCommentsCount,
  onSwitchView,
}: {
  showResolvedComments: boolean;
  onSwitchView: () => void;
  resolvedCommentsCount: number;
}) => {
  if (showResolvedComments) {
    return (
      <Button
        variant="primary"
        my="space2"
        onClick={onSwitchView}
        style={{ alignSelf: 'center' }}
      >
        {resolvedCommentsCount > 0
          ? 'Hide resolved comments'
          : 'Go back to comments'}
      </Button>
    );
  }

  if (resolvedCommentsCount > 0) {
    return (
      <Button
        variant="subtle"
        my="space2"
        onClick={onSwitchView}
        style={{ alignSelf: 'center' }}
      >
        Show {resolvedCommentsCount} resolved{' '}
        {resolvedCommentsCount > 1 ? 'comments' : 'comment'}
      </Button>
    );
  }

  return null;
};
