import { type PropsWithChildren } from 'react';
import { Box } from '@mentimeter/ragnar-ui';

interface OverviewListItemControlsProps {
  isActive: boolean;
}

export const OverviewListItemControls = ({
  isActive,
  children,
}: PropsWithChildren<OverviewListItemControlsProps>) => {
  return (
    <Box
      justifyContent="space-between"
      flexDirection="column"
      alignItems="center"
      extend={({ theme }) => ({
        width: '32px',
        position: 'relative',
        '&:before': isActive
          ? {
              content: '""',
              position: 'absolute',
              height: '28px',
              transform: 'translateY(-50%)',
              left: 0,
              top: '50%',
              width: '3px',
              borderRadius: `${theme.radii[1]}px`,
              backgroundColor: theme.colors.secondary,
            }
          : {},
      })}
    >
      {children}
    </Box>
  );
};
