// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostRecentRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** Presentation ID */
  id: string;
}

export function postRecentRequest(requestParams: PostRecentRequest): Request {
  const data = {
    id: toSnakeKeys(requestParams.id),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/presentation-collection/recent`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
