import { type BoxT, Box, IconButton } from '@mentimeter/ragnar-ui';
import { lighten, purpleDark500 } from '@mentimeter/ragnar-colors';
import { MoreIcon } from '@mentimeter/ragnar-visuals';
import { type MouseEventHandler } from 'react';

interface CanvasAreaProps extends BoxT {
  active?: boolean | undefined;
  showBorder?: boolean | undefined;
  scaleFactor?: number | undefined;
  moreLabel?: string | undefined;
  onMoreClick?: MouseEventHandler | undefined;
  moreButtonId?: string | undefined;
}
export const OUTLINE_COLOR = purpleDark500;

export const CanvasArea = ({
  active,
  children,
  extend: extendProp,
  showBorder,
  scaleFactor = 1,
  moreLabel,
  onMoreClick,
  moreButtonId,
  ...boxProps
}: CanvasAreaProps) => {
  return (
    <Box
      p="space4"
      className="canvasArea" // className is needed in EditorPresentation for hover styles
      aria-selected={active}
      borderRadius="2xl"
      {...boxProps}
      extend={({ theme }) => ({
        ...(extendProp?.({ theme }) || {}),
        cursor: 'pointer',
        outlineStyle: 'solid',
        outlineWidth: active || showBorder ? `${2 * scaleFactor}px` : 0,
        outlineColor: OUTLINE_COLOR,
        '&:after': {
          borderRadius: 'inherit',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          content: '""',
          boxShadow: `inset 0 0 0 ${(theme.borders[2] || 0) * scaleFactor}px ${active ? '#FFF' : 'transparent'}`,
          pointerEvents: 'none',
        },
        '&:hover:after': {
          boxShadow: `inset 0 0 0 ${(theme.borders[2] || 0) * scaleFactor}px ${'#FFF'}`,
        },
        transition: `${theme.durations[0]}s ease`,
        '&:hover': {
          outlineWidth: `${2 * scaleFactor}px`,
          outlineColor: active ? undefined : lighten(OUTLINE_COLOR, 0.5),
        },
        '&:focus-within': {
          outlineWidth: `${2 * scaleFactor}px`,
          outlineColor: active ? undefined : lighten(OUTLINE_COLOR, 0.5),
        },
        '&:focus-within:after': {
          boxShadow: `inset 0 0 0 ${(theme.borders[2] || 0) * scaleFactor}px ${'#FFF'}`,
        },
        pointerEvents: 'auto',
      })}
    >
      {/* Hidden but keyboard accessible button */}
      {onMoreClick && (
        <IconButton
          id={moreButtonId}
          variant="tertiary"
          size="compact"
          aria-label={moreLabel}
          position="absolute"
          top={0}
          right={0}
          m="space4"
          onClick={onMoreClick}
          extend={() => ({
            transformOrigin: 'top right',
            transform: `scale(${scaleFactor})`,
            opacity: 0,
            pointerEvents: 'none',
            '&:focus-visible': {
              opacity: 1,
              pointerEvents: 'auto',
            },
            '&:not(:disabled):active': {
              transform: `scale(${scaleFactor}) `,
            },
          })}
        >
          <MoreIcon />
        </IconButton>
      )}
      {children}
    </Box>
  );
};
