import {
  PopoverAnchor,
  PopoverContainer,
  PopoverRoot,
} from '@mentimeter/ragnar-ui';
import type { ReactRendererComponentType } from './suggestion';

export const MentionSuggestionsPopOver = ({
  showPopover,
  position,
  suggestionContent,
}: {
  showPopover: boolean;
  position: DOMRect | null;
  suggestionContent: ReactRendererComponentType | undefined;
}) => (
  <PopoverRoot open={showPopover}>
    {position && (
      <PopoverAnchor
        virtualRef={{
          current: {
            getBoundingClientRect: () => position,
          },
        }}
      />
    )}
    <PopoverContainer
      align="start"
      onOpenAutoFocus={(e: Event) => e.preventDefault()}
    >
      {suggestionContent?.reactElement}
    </PopoverContainer>
  </PopoverRoot>
);
