import React, { useEffect, useRef, useState } from 'react';

interface Props {
  root?: HTMLElement | null;
  children: React.ReactNode;
}

const INITIAL_PLACEHOLDER_HEIGHT = 124; // approximate thumbnail height
const VISIBLITY_TIMEOUT = 600;

export const RenderIfVisible = ({ root = null, children }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const placeholderHeight = useRef<number>(INITIAL_PLACEHOLDER_HEIGHT);
  const intersectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (intersectionRef.current) {
      const localRef = intersectionRef.current;
      const observer = new IntersectionObserver(
        (entries) => {
          if (!entries[0]?.isIntersecting) {
            placeholderHeight.current = localRef!.offsetHeight;
          }
          if (typeof window !== undefined && window.requestIdleCallback) {
            window.requestIdleCallback(
              () => setIsVisible(!!entries[0]?.isIntersecting),
              {
                timeout: VISIBLITY_TIMEOUT,
              },
            );
          } else {
            setIsVisible(!!entries[0]?.isIntersecting);
          }
        },
        {
          root,
        },
      );

      observer.observe(localRef);
      return () => {
        if (localRef) {
          observer.unobserve(localRef);
        }
      };
    }
    return () => {};
  }, [root]);

  return (
    <div ref={intersectionRef}>
      {isVisible ? (
        children
      ) : (
        <div
          style={{
            height: placeholderHeight.current,
          }}
        />
      )}
    </div>
  );
};
