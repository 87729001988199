import { Box, type BoxT } from '@mentimeter/ragnar-ui';

interface SurfaceProps extends BoxT {
  children: React.ReactNode;
}

export const Surface = (props: SurfaceProps) => (
  <Box
    alignItems="inherit"
    flexDirection="inherit"
    bg="bg"
    width={[null, '100%', null]}
    borderRadius="2xl"
    {...props}
  />
);
