import type { SidebarPaneId } from '@mentimeter/editor-essentials/types';
import {
  Bar,
  SIDEBAR_PANE_WIDTH,
  TabbedInterface,
} from '@mentimeter/editor-properties-pane';
import { useDeviceChecker } from '@mentimeter/ragnar-device';
import { Box } from '@mentimeter/ragnar-ui';

export const SidebarTabbedInterface = ({
  activePane,
  handleSetActiveSidebarTab,
  isPaneOpen,
  children,
}: {
  activePane: SidebarPaneId | '';
  handleSetActiveSidebarTab: (activePaneId: string) => void;
  isPaneOpen: boolean;
  children: React.ReactNode;
}) => {
  const { isSmallDevice } = useDeviceChecker();

  return (
    <TabbedInterface
      id="sidebar"
      activeTabId={activePane !== 'closed' ? activePane : ''}
      setActiveTabId={handleSetActiveSidebarTab}
      dismissable
    >
      <Box
        as="section"
        aria-label="Sidebar"
        id="content-step--id" // for new user tour
        position={['absolute', 'static', null]}
        bottom={0}
        height={[isPaneOpen ? '100%' : 'auto', '100%', null]}
        width={['100%', 'auto', null]}
        flex={['1 1 auto', '0 0 auto', null]}
        flexDirection={['column-reverse', 'row-reverse', null]}
        alignItems="stretch"
        extend={({ theme }) => ({
          paddingLeft:
            isPaneOpen && !isSmallDevice
              ? `${SIDEBAR_PANE_WIDTH + theme.space[3]}px`
              : undefined,
        })}
      >
        <Bar>{children}</Bar>
      </Box>
    </TabbedInterface>
  );
};
