import { useMatch } from '@mentimeter/ragnar-device';
import { TabList } from '../../components/tabs/TabList';

interface TabBarProps {
  children: React.ReactNode;
}

export const TabBar = ({ children }: TabBarProps) => {
  const isMobile = useMatch({ lessThan: 2 });
  return (
    <TabList
      orientation={isMobile ? 'horizontal' : 'vertical'}
      alignItems="inherit"
      flexDirection="inherit"
      width={[null, '100%', null]}
      gap="space4"
    >
      {children}
    </TabList>
  );
};
