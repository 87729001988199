/* eslint-disable menti-react/filename-convention--jsx */
import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { useQfa } from '../../../data-mediation';

interface QfaIndicatorT {
  newAvailable: boolean;
}

const C = React.memo<QfaIndicatorT>(({ newAvailable }: QfaIndicatorT) => {
  const ICON_SIZE = 30;
  return (
    <Box
      position="absolute"
      zIndex={2}
      right={0}
      width={ICON_SIZE / 2.5}
      height={ICON_SIZE / 2.5}
      bg="primary"
      borderRadius={3}
      extend={({ theme }) => ({
        opacity: newAvailable ? 1 : 0,
        transition: `opacity ${theme.durations[1]}s ease`,
        transform: 'translate(-25%,25%)',
      })}
    />
  );
});

export const IndicationDot = () => {
  const { newAvailable } = useQfa();

  return <C newAvailable={newAvailable} />;
};
