import { SIDEBAR_BACK_BUTTON_ID } from '@mentimeter/editor-essentials/constants';
import { Box, Clickable, Text } from '@mentimeter/ragnar-ui';
import { ArrowLeftIcon, CrossIcon } from '@mentimeter/ragnar-visuals';

interface PaneTitleProps {
  label: string;
  onDismiss: () => void;
  onBackClick?: (() => void) | undefined;
}

export const PaneTitle = ({
  label,
  onDismiss,
  onBackClick,
}: PaneTitleProps) => (
  <Box
    flexDirection="row"
    alignItems="center"
    pt="space5"
    pb="space4"
    pl="space8"
    pr="space5"
    gap="space2"
  >
    {onBackClick && (
      <Clickable
        id={SIDEBAR_BACK_BUTTON_ID}
        aria-label="Back to slide edit"
        ml="-space2"
        p="space2"
        onClick={onBackClick}
      >
        <ArrowLeftIcon size={2} />
      </Clickable>
    )}
    <Box flex="1 1 auto" flexDirection="row" alignItems="center">
      <Text as="h2" id="sidebar-pane-title" color="text" fontWeight="regular">
        {label}
      </Text>
    </Box>
    <Clickable aria-label={`Close ${label}`} p="space2" onClick={onDismiss}>
      <CrossIcon size={2} />
    </Clickable>
  </Box>
);
