import { filter } from '@mentimeter/hotkeys';
import type { ReactElement } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { OVERVIEW_LIST_ITEM_ID_PREFIX } from '../../constants';
import { KeyboardHandlerContext } from './KeyboardHandlerContext';

interface KeyboardHandlerProviderProps {
  activateQuestion: (questionId: string) => void;
  questionIds: string[];
  activeQuestionIndex: number;
  children: ReactElement;
}

const DRAG_HANDLER_PREFFIX = 'drag-handler';

export const KeyboardHandlerProvider = ({
  activateQuestion,
  questionIds,
  activeQuestionIndex,
  children,
}: KeyboardHandlerProviderProps) => {
  const [disabled, setDisabled] = useState(false);

  const onActivateQuestion = useCallback(
    (e: React.SyntheticEvent<HTMLElement>, questionId: string) => {
      if (questionId) {
        if (
          !filter.inputIsFocused(e) && // not a form element
          (e.target as Element).getAttribute('role') !== 'listbox' && // not a listbox
          (e.target as Element).getAttribute('role') !== 'menu' && // not a menu
          (e.target as Element).getAttribute('role') !== 'menuitem' && // not a menu item
          (e.target as Element).getAttribute('role') !== 'menuitemradio' &&
          (e.target as Element).getAttribute('role') !== 'menuitemcheckbox' &&
          !(e.target as Element)
            .getAttribute('id')
            ?.includes(DRAG_HANDLER_PREFFIX) && // not a drag handle
          (e.target as Element).nodeName !== 'BUTTON' && // not a button
          !filter.focusInModal(e) // not inside a modal
        ) {
          e.preventDefault();
          activateQuestion(questionId);
          // On keyboard slide activation, focus the overview item corresponding to the activated question
          document
            .getElementById(`${OVERVIEW_LIST_ITEM_ID_PREFIX}${questionId}`)
            ?.focus();
        }
      }
    },
    [activateQuestion],
  );

  const onPreviousQuestion = useCallback(
    (event: KeyboardEvent) =>
      // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      onActivateQuestion(event, questionIds[activeQuestionIndex - 1]),
    [activeQuestionIndex, onActivateQuestion, questionIds],
  );

  const onNextQuestion = useCallback(
    (event: KeyboardEvent) =>
      // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      onActivateQuestion(event, questionIds[activeQuestionIndex + 1]),
    [activeQuestionIndex, onActivateQuestion, questionIds],
  );

  const onFirstQuestion = useCallback(
    // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    (event: KeyboardEvent) => onActivateQuestion(event, questionIds[0]),
    [onActivateQuestion, questionIds],
  );

  const onLastQuestion = useCallback(
    (event: KeyboardEvent) =>
      // @ts-expect-error-auto TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      onActivateQuestion(event, questionIds[questionIds.length - 1]),
    [onActivateQuestion, questionIds],
  );

  const eventHandler = useCallback(
    (event: KeyboardEvent) => {
      const modifierState =
        navigator.platform.indexOf('Win') >= 0 ? 'Control' : 'Meta';

      if (disabled) {
        return;
      }
      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowRight':
          if (event.getModifierState(modifierState)) {
            return onLastQuestion(event);
          }
          return onNextQuestion(event);
        case 'PageDown':
          return onNextQuestion(event);
        case 'ArrowUp':
        case 'ArrowLeft':
          if (event.getModifierState(modifierState)) {
            return onFirstQuestion(event);
          }
          return onPreviousQuestion(event);
        case 'PageUp':
          return onPreviousQuestion(event);
        case 'Home':
          return onFirstQuestion(event);
        case 'End':
          return onLastQuestion(event);
      }
    },
    [
      disabled,
      onFirstQuestion,
      onLastQuestion,
      onNextQuestion,
      onPreviousQuestion,
    ],
  );

  useEffect(() => {
    document.addEventListener('keydown', eventHandler);

    return () => {
      document.removeEventListener('keydown', eventHandler);
    };
  }, [eventHandler]);

  const value = {
    disable: setDisabled,
  };

  return (
    <KeyboardHandlerContext.Provider value={value}>
      {children}
    </KeyboardHandlerContext.Provider>
  );
};
