import React from 'react';
import type { TabInternal } from './utils';

export interface TabbedInterfaceContextValue {
  id: string;
  dismissable: boolean;
  activeTabId: string;
  focusableTabId: string;
  setActiveTabId: React.Dispatch<React.SetStateAction<string>>;
  selectPreviousTab: () => void;
  selectNextTab: () => void;
  registerTab: (tab: TabInternal) => void;
}

export const TabbedInterfaceContext = React.createContext<
  TabbedInterfaceContextValue | undefined
>(undefined);
TabbedInterfaceContext.displayName = 'TabbedInterfaceContext';
