import type { Comment } from '@core-api/comments/types/response';
import { useRagnar } from '@mentimeter/ragnar-react';
import { Box } from '@mentimeter/ragnar-ui';
import { CornerDownRightIcon } from '@mentimeter/ragnar-visuals';
import { forwardRef, useState } from 'react';
import { newCommentSlideInAnimation } from '../util/animations';
import { CommentDetails } from './CommentDetails';

export interface CommentCardProps {
  comment: Comment;
  isOwner: boolean;
  isReply: boolean;
  lastCommentId?: string | undefined;
}

export const CommentCard = forwardRef<HTMLDivElement, CommentCardProps>(
  ({ isOwner, isReply, comment, lastCommentId }, ref) => {
    const { renderer } = useRagnar();
    const [hoveredCommentId, setHoveredCommentId] = useState<string | null>(
      null,
    );
    const [showActionMenuDropDown, setShowActionMenuDropDown] = useState(false);
    const [showReplyInput, setShowReplyInput] = useState(false);

    const handleMouseOver = () => {
      if (!showActionMenuDropDown && !showReplyInput)
        setHoveredCommentId(comment.id);
    };

    const handleMouseLeave = () => {
      if (!showActionMenuDropDown && !showReplyInput) setHoveredCommentId(null);
    };

    const handleOpenCommentActionsChange = () => {
      setShowActionMenuDropDown(!showActionMenuDropDown);
    };

    const commentIsNew =
      new Date(comment.createdAt).getTime() > Date.now() - 2000;

    return (
      <Box
        data-testid={`comment-card-${comment.id}`}
        ref={ref}
        p="space3"
        style={
          commentIsNew ? newCommentSlideInAnimation(renderer, isReply) : {}
        }
        extend={({ theme }) => ({
          transition: 'all 1s ease',
          '@media(hover: hover)': {
            '&:hover': {
              backgroundColor: theme.colors.surfaceHover,
              borderRadius: `${theme.kosmosBorderRadius.lg}px`,
            },
          },
        })}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        display={isReply ? 'flex' : 'block'}
        flexDirection={isReply ? 'row' : 'unset'}
        justifyContent={isReply ? 'space-between' : 'unset'}
        width="100%"
      >
        {isReply && (
          <Box mr="space3" py="space2">
            <CornerDownRightIcon
              color="textWeaker"
              size={0}
              aria-label="Reply to comment"
            />
          </Box>
        )}
        <Box flex="1 1 auto" display="block">
          <CommentDetails
            comment={comment}
            isOwner={isOwner}
            displayActionMenu={hoveredCommentId === comment.id}
            displayReplyButton={
              hoveredCommentId === (isReply ? lastCommentId : comment.id)
            }
            onOpenCommentActionsChange={handleOpenCommentActionsChange}
            showReplyInput={showReplyInput}
            onShowReplyChange={setShowReplyInput}
          />
        </Box>
      </Box>
    );
  },
);
