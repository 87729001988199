import type { PropsWithChildren } from 'react';
import * as React from 'react';
import { Box, ImageProgressive } from '@mentimeter/ragnar-ui';
import type { Extend } from '@mentimeter/ragnar-dsc';
import type { Question } from '@mentimeter/http-clients';
import { ThemeProvider } from '../sfonx/slide-layout/Theme';
import { Background } from '../sfonx/slide-layout/Background';
import { TranslationProvider } from '../sfonx/data-mediation';
import { PresentationCanvas } from '../sfonx/slide-layout/PresentationCanvas';
import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../sfonx/utils/constants';
import { ConfigProvider } from '../sfonx/misc/config';
import type { SfinxConfigData } from '../sfonx/misc/config/types';
import { SideBySideImage } from '../sfonx/slide-layout/SideBySideImage';

export type ThumbnailItem = Pick<
  Question,
  | 'id'
  | 'override_theme'
  | 'theme_settings'
  | 'image'
  | 'image_alt_description'
  | 'type'
  | 'sub_type'
  | 'question'
  | 'layout'
  | 'image_as_background'
  | 'slide_image_type'
  | 'slide_type'
>;

interface ThumbnailT {
  extend?: Extend;
  children: React.ReactNode;
  thumbnailItem: ThumbnailItem;
}

type Props = PropsWithChildren<{
  config: SfinxConfigData;
  presentationTheme: React.ComponentProps<
    typeof ThemeProvider
  >['presentationTheme'];
  thumbnailItem: ThumbnailItem;
}>;

/**
 * Provider for small thumbnails
 */
export const ThumbnailProvider = ({
  config,
  presentationTheme,
  thumbnailItem,
  children,
}: Props) => {
  return (
    <ConfigProvider value={config}>
      <TranslationProvider>
        <ThemeProvider
          presentationTheme={presentationTheme}
          slideTheme={
            thumbnailItem?.override_theme
              ? thumbnailItem.theme_settings
              : undefined
          }
        >
          {children}
        </ThemeProvider>
      </TranslationProvider>
    </ConfigProvider>
  );
};

export const Thumbnail = ({ extend, children, thumbnailItem }: ThumbnailT) => {
  const {
    image,
    image_alt_description,
    id,
    type,
    sub_type,
    slide_image_type,
    theme_settings,
    layout,
  } = thumbnailItem;

  const thumbnailImageUrl = image?.presets.small.url;

  const showAsImageFrame =
    thumbnailImageUrl &&
    slide_image_type === 'image-frame' &&
    type === 'slide' &&
    sub_type === 'image';

  if (!id) return null;

  return (
    <Box
      zIndex={1}
      bg="bg"
      width={CANVAS_WIDTH}
      height={CANVAS_HEIGHT}
      extend={extend}
    >
      <PresentationCanvas
        width="100%"
        height="100%"
        flexDirection="row"
        disableClickEvents
        renderBackground={false}
        slideImage={theme_settings?.background_cropped_image_url}
      >
        <Background backgroundItem={thumbnailItem}>
          <Box
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            flex="1 1 auto"
          >
            {showAsImageFrame ? (
              <Box
                width="100%"
                height="100%"
                data-testid="thumbnail-image-frame"
              >
                <ImageProgressive
                  src={thumbnailImageUrl}
                  alt={image_alt_description || ''}
                  stretch
                />
              </Box>
            ) : (
              <SideBySideImage
                imageUrl={thumbnailImageUrl}
                imageAltText={image_alt_description}
                layoutType={layout?.type}
              >
                <Box
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  px="space4"
                >
                  {children}
                </Box>
              </SideBySideImage>
            )}
          </Box>
        </Background>
      </PresentationCanvas>
    </Box>
  );
};
