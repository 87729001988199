import { useRagnar, type IRenderer } from '@mentimeter/ragnar-react';
import { Box, Tooltip } from '@mentimeter/ragnar-ui';
import { CommentSolidIcon } from '@mentimeter/ragnar-visuals';

const commentIconAnimationStyle = (renderer: IRenderer) => {
  const swingKeyframe =
    renderer.renderKeyframe &&
    renderer.renderKeyframe(
      () => ({
        '25%': { transform: 'rotate(-20deg)' },
        '50%': { transform: 'rotate(20deg)' },
        '75%': { transform: 'rotate(-12deg)' },
        '80%': { transform: 'rotate(12deg)' },
        '90%': { transform: 'rotate(-5deg)' },
        '95%': { transform: 'rotate(5deg)' },
        '100%': { transform: 'rotate(0)' },
      }),
      {},
    );

  return {
    animationDuration: '1s',
    animationIterationCount: 1,
    animationName: swingKeyframe,
    transformOrigin: 'center', // Set the origin to the top center for a bottom swing effect
  };
};

export const OverviewListItemCommentIcon = ({
  itemId,
  onClick,
}: { itemId: string; onClick: () => void }) => {
  const { renderer } = useRagnar();
  return (
    <>
      <Box
        id={`comment-tooltip-${itemId}`}
        my="space0.5"
        aria-hidden
        style={commentIconAnimationStyle(renderer)}
        extend={() => ({
          cursor: 'pointer',
        })}
        onClick={onClick}
      >
        <CommentSolidIcon size={1} color="info" />
      </Box>
      <Tooltip
        referenceId={`comment-tooltip-${itemId}`}
        description="See and reply to comments on this slide"
        placement="right"
      />
    </>
  );
};
