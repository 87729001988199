import {
  ModalRoot,
  ModalContainer,
  ModalFooter,
  ModalDismiss,
  ModalBody,
  ModalHeader,
  Button,
  useButtonState,
} from '@mentimeter/ragnar-ui';
import userCache from '@mentimeter/user';
import {
  deleteCommentsRequest,
  type DeleteCommentsRequest,
} from '@core-api/comments/comments/{id}';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { useSearchParams } from '@mentimeter/next-navigation';
import { useRevalidateEditorComments } from '../hooks/useRevalidateEditorComments';

export const DeleteModalDataHandler = ({
  open,
  commentId,
  onOpenDeleteModalChange,
}: {
  open: boolean;
  commentId: string;
  onOpenDeleteModalChange: () => void;
}) => {
  const [buttonState, setButtonState] = useButtonState();
  const questionAdminKey = useSearchParams()?.get('question') || '';
  const seriesId = useActiveSeriesId();
  const { revalidateEditorComments } = useRevalidateEditorComments(
    seriesId,
    questionAdminKey,
  );

  const handleDelete = async () => {
    setButtonState('loading');

    const region = userCache.region;
    const sessionToken = userCache.getToken();

    const params: DeleteCommentsRequest = {
      region,
      userAuth: sessionToken,
      id: commentId,
    };

    try {
      const response = await fetch(deleteCommentsRequest(params));

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      onOpenDeleteModalChange();
      await revalidateEditorComments();
    } catch (error) {
      const err = new MentiError('error deleting a comment', {
        feature: 'comments-pane-editor',
        cause: error,
      });
      captureException(err);
      setButtonState('error');
    }
  };

  return (
    <ModalRoot open={open} onOpenChange={onOpenDeleteModalChange}>
      <ModalContainer size="small">
        <ModalHeader>Are you sure you want to delete this comment?</ModalHeader>
        <ModalBody>This cannot be undone.</ModalBody>
        <ModalFooter>
          <ModalDismiss>
            <Button variant="secondary">Cancel</Button>
          </ModalDismiss>
          <Button
            state={buttonState}
            variant="negativeWeak"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};
