import { HotkeyHandler, HotkeyProvider } from '@mentimeter/hotkeys';
import { Box } from '@mentimeter/ragnar-ui';
import type { BoxT } from '@mentimeter/ragnar-ui';
import React from 'react';
import { useTabbedInterface } from './hooks';

interface TabListProps extends BoxT {
  orientation: 'horizontal' | 'vertical';
  children: React.ReactNode;
}

export const TabList = ({
  orientation = 'horizontal',
  children,
  ...boxProps
}: TabListProps) => {
  const { selectPreviousTab, selectNextTab } = useTabbedInterface();
  const [isFocused, setIsFocused] = React.useState(false);

  const handlePreviousTab = (e: React.KeyboardEvent<HTMLElement>) => {
    e.preventDefault();
    selectPreviousTab();
  };
  const handleNextTab = (e: React.KeyboardEvent<HTMLElement>) => {
    e.preventDefault();
    selectNextTab();
  };

  return (
    <HotkeyProvider
      keyStrokes={['arrowleft', 'arrowright', 'arrowup', 'arrowdown']}
    >
      {/* Only add hotkey handlers if the component is focused so that there 
      are no issues if multiple tablists are rendered at the same time */}
      {isFocused &&
        (orientation === 'horizontal' ? (
          <>
            <HotkeyHandler keyCode="arrowleft" onKeyDown={handlePreviousTab} />
            <HotkeyHandler keyCode="arrowright" onKeyDown={handleNextTab} />
          </>
        ) : (
          <>
            <HotkeyHandler keyCode="arrowup" onKeyDown={handlePreviousTab} />
            <HotkeyHandler keyCode="arrowdown" onKeyDown={handleNextTab} />
          </>
        ))}

      <Box
        flexDirection={orientation === 'horizontal' ? 'row' : 'column'}
        {...boxProps}
        role="tablist"
        aria-orientation={orientation}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {children}
      </Box>
    </HotkeyProvider>
  );
};
