import { Button } from '@mentimeter/ragnar-ui';
import type { Comment } from '@core-api/comments/types/response';
import { CommentsControlDataHandler } from './CommentsControlDataHandler';

export const ReplyDataHandler = ({
  comment,
  showReplyInput,
  onShowReplyChange,
  displayReplyButton,
  isOwner,
}: {
  comment: Comment;
  showReplyInput: boolean;
  onShowReplyChange: (arg: boolean) => void;
  displayReplyButton: boolean;
  isOwner: boolean;
}) => {
  const topLevelCommentId =
    comment.parentId === null ? comment.id : comment.parentId;

  if (!displayReplyButton) return null;

  if (showReplyInput)
    return (
      <CommentsControlDataHandler
        showControlButtonsInitially={true}
        onCancel={() => onShowReplyChange(false)}
        onSubmitComment={() => onShowReplyChange(false)}
        cta="Reply"
        placeholder="Add a reply"
        parentCommentId={topLevelCommentId}
        isOwner={isOwner}
      />
    );

  return (
    <Button
      variant="subtle"
      size="compact"
      onClick={() => onShowReplyChange(true)}
      extend={() => ({
        transition: 'all 0.2s ease',
      })}
    >
      Reply
    </Button>
  );
};
