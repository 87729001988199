import * as React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import { Svg } from '../../shared-components';
import { COLOR_MAP } from '../colorMap';
import type { Pictogram } from '../types';

export const Comment = ({ userColor, variant = 'purple' }: Pictogram) => {
  return (
    <Svg viewBox="0 0 200 200" aria-hidden="true" className="light">
      <path
        d="M128 52H44V124H60L80 140V124H128V52Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramPrimary}
      />
      <path
        d="M156 92H96V148H116V160L132 148H156V92Z"
        fill={
          userColor
            ? userColor && alpha(userColor, 0.6)
            : COLOR_MAP[variant].pictogramTertiary
        }
      />
      <rect
        x="96"
        y="92"
        width="32"
        height="32"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramSecondary}
      />
    </Svg>
  );
};
