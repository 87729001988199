import { Box } from '@mentimeter/ragnar-ui';

interface ButtonBarProps {
  children: React.ReactNode;
}

export const ButtonBar = ({ children }: ButtonBarProps) => {
  return (
    <Box
      alignItems="inherit"
      flexDirection="inherit"
      gap={1}
      minWidth={['88px', '56px', '96px']}
      height={['auto', '56px', '84px']}
    >
      {children}
    </Box>
  );
};
