import { useEffect, useState } from 'react';
import { IconButton, Tooltip, useButtonState } from '@mentimeter/ragnar-ui';
import { CheckIcon, RefreshIcon } from '@mentimeter/ragnar-visuals';
import userCache from '@mentimeter/user';
import {
  patchCommentsRequest,
  type PatchCommentsRequest,
} from '@core-api/comments/comments/{id}';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import { useEditorSeries } from '@mentimeter/editor-data-hooks';
import { trackUser } from '@api/tracking/client';
import { useActiveSeriesId } from '@mentimeter/core-hooks/use-ids';
import { useSearchParams } from '@mentimeter/next-navigation';
import type { Comment } from '@core-api/comments/types/response';
import { useCommentsTrackingContext } from '../components/CommentsTrackingContextProvider';
import { useRevalidateEditorComments } from '../hooks/useRevalidateEditorComments';

export const ResolveCommentDataHandler = ({
  comment,
}: {
  comment: Comment;
}) => {
  const [error, setError] = useState(false);
  const [buttonState, setButtonState] = useButtonState();

  useEffect(() => {
    const timeout = error ? setTimeout(setError, 2000) : undefined;
    return () => clearTimeout(timeout);
  }, [error]);

  const seriesId = useActiveSeriesId();
  const { series } = useEditorSeries(seriesId);
  const questionAdminKey =
    useSearchParams()?.get('question') || series?.questions[0]?.admin_key || '';
  const { revalidateEditorComments } = useRevalidateEditorComments(
    seriesId,
    questionAdminKey,
  );
  const isOwner = userCache.user_id === series?.owner_id;
  const context = useCommentsTrackingContext();

  const handleResolveComment = async () => {
    setButtonState('loading');
    const region = userCache.region;
    const sessionToken = userCache.getToken();

    const params: PatchCommentsRequest = {
      region,
      userAuth: sessionToken,
      id: comment.id,
      resolve: !comment.resolvedAt,
    };

    try {
      const response = await fetch(patchCommentsRequest(params));
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      await revalidateEditorComments();
      trackUser({
        event: 'Comment resolved',
        properties: {
          context,
          placement: 'Comments tab',
          owner: isOwner,
          author: comment.authorId === userCache.user_id,
        },
      });
    } catch (error) {
      const err = new MentiError('error resolving a comment', {
        feature: 'comments-pane-editor',
        cause: error,
      });
      captureException(err);
      setError(true);
    }
  };

  return (
    <>
      <Tooltip
        referenceId={`resolve-comment-${comment.id}`}
        description={comment.resolvedAt ? 'Reopen' : 'Resolve'}
        placement="bottom"
      />
      <IconButton
        id={`resolve-comment-${comment.id}`}
        aria-label={`${comment.resolvedAt ? 'un resolve' : 'resolve'} comment from ${comment.authorDisplayName}`}
        size="compact"
        borderRadius="full"
        variant={error ? 'negativeWeak' : 'secondary'}
        onClick={handleResolveComment}
        state={buttonState}
      >
        {comment.resolvedAt ? <RefreshIcon /> : <CheckIcon />}
      </IconButton>
    </>
  );
};
