// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toQueryParam } from '@api/internal';

export interface GetCommentsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The admin key used to specify which question's comments should be listed. */
  questionAdminKey: string;
}

export function getCommentsRequest(requestParams: GetCommentsRequest): Request {
  const queryParams = new URLSearchParams();
  queryParams.set(
    'question_admin_key',
    toQueryParam(requestParams.questionAdminKey),
  );

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/comments?${queryParams.toString()}`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}
