import React from 'react';
import { OVERVIEW_LIST_ITEM_ID_PREFIX } from '@mentimeter/editor-essentials/constants';
import { Box } from '@mentimeter/ragnar-ui';
import {
  type DraggableAttributes,
  type DraggableSyntheticListeners,
} from '@dnd-kit/core';
import type { DSC } from '@mentimeter/ragnar-dsc';

interface OverviewListItemProps {
  slideId: string;
  isSelected?: boolean | undefined;
  isDragging?: boolean | undefined;
  isDraggedElement?: boolean | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  setNodeRef?: (element: HTMLElement | null) => void;
  listeners?: DraggableSyntheticListeners | undefined;
  style?: React.CSSProperties;
  attributes?: DraggableAttributes;
}

const getOpacity = (
  theme: DSC,
  isSelected: boolean | undefined,
  isDragging: boolean | undefined,
  isDraggedElement: boolean | undefined,
) => {
  if (isDraggedElement) return 0;
  if (isSelected && isDragging) return theme.opacity.light;
  return 1;
};

export const OverviewListItem = ({
  children,
  slideId,
  isSelected,
  isDragging,
  isDraggedElement,
  listeners,
  onClick,
  setNodeRef,
  style,
  attributes,
  ...htmlProps
}: React.PropsWithChildren<OverviewListItemProps>) => {
  return (
    <Box // Drag and Drop container, also keyboard navigation node
      {...htmlProps}
      id={`${OVERVIEW_LIST_ITEM_ID_PREFIX}${slideId}`}
      as="li"
      ref={(ref: HTMLLIElement) => setNodeRef?.(ref)}
      flexDirection="row"
      alignItems="stretch"
      mb={3}
      style={style}
      extend={({ theme }) => ({
        isolation: 'isolate',
        zIndex: isSelected && isDragging ? 10 : 'initial',
        opacity: getOpacity(theme, isSelected, isDragging, isDraggedElement),
        '&:focus-visible .thumbnail': {
          outline: `4px solid ${theme.colors.interactiveFocused}`,
          outlineOffset: '2px',
        },
        [`&:hover #overview-actions-${slideId}`]: {
          opacity: 1,
        },
        transition: 'opacity 0.2s, background 0.2s',
        position: 'relative',
        justifyContent: 'center',
        display: 'flex',
      })}
      onClick={onClick}
      {...listeners}
      {...attributes}
    >
      {children}
    </Box>
  );
};
