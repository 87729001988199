import React from 'react';
import { Box, Text } from '@mentimeter/ragnar-ui';
import { useSortable } from '@dnd-kit/sortable';
import { OverviewListItemControls } from './OverviewListItemControls';
import { OverviewListItemThumbnailScaling } from './OverviewListItemThumbnailScaling';
import { OVERVIEW_SLIDE_WIDTH } from './constants';

export const OverviewDragOverlay = ({
  numberOfDraggedItems,
  bgColor,
  textColor,
  items,
  activeItemId,
}: {
  numberOfDraggedItems: number;
  bgColor: string;
  textColor: string;
  items: { id: string }[];
  activeItemId: string | undefined;
}) => {
  const { activeIndex: draggedElementIndex } = useSortable({
    id: 'overlay',
  });

  const draggedItem = items[draggedElementIndex];
  const isDraggedItemActive =
    Boolean(draggedItem?.id) && draggedItem?.id === activeItemId;

  return (
    <Box
      id="overlay"
      pr={3}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="stretch"
      width={OVERVIEW_SLIDE_WIDTH}
      maxWidth="100%"
    >
      <OverviewListItemControls isActive={isDraggedItemActive}>
        {draggedElementIndex >= 0 ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="initial"
            gridRowStart={1}
            gridRowEnd={4}
          >
            <Text
              fontSize="75"
              color="text"
              textAlign="center"
              extend={() => ({
                maxWidth: 'none', // because of font-scaling we want this to be able to overflow
              })}
            >
              {draggedElementIndex + 1}
            </Text>
          </Box>
        ) : null}
      </OverviewListItemControls>
      <OverviewListItemThumbnailScaling
        isDraggingGroup={numberOfDraggedItems > 1}
        bgColor={bgColor}
        textColor={textColor}
      >
        <Box
          zIndex={1}
          width={1920}
          height={1080}
          alignItems="center"
          justifyContent="center"
          extend={() => ({ gap: '3%' })}
        >
          <Box
            bg={textColor}
            borderRadius="full"
            height="270px"
            width="270px"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              fontSize={170}
              fontWeight="semiBold"
              color={bgColor}
              mb="space2"
            >
              {numberOfDraggedItems}
            </Text>
          </Box>
          <Text fontSize={180} color={textColor}>
            slide{numberOfDraggedItems > 1 ? 's' : ''}
          </Text>
        </Box>
      </OverviewListItemThumbnailScaling>
    </Box>
  );
};
