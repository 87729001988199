import React from 'react';
import { getItemByPosition, sortItems } from './utils';
import type { TabInternal } from './utils';
import { TabbedInterfaceContext } from './context';

interface TabbedInterfaceProps {
  id: string;
  dismissable?: boolean;
  activeTabId: string;
  setActiveTabId: (activeTabId: string) => void;
  children: React.ReactNode;
}

export const TabbedInterface = ({
  id,
  dismissable,
  activeTabId,
  setActiveTabId,
  children,
}: TabbedInterfaceProps) => {
  const [tabs, setTabs] = React.useState<TabInternal[]>([]);

  const focusableTabId =
    activeTabId || getItemByPosition(tabs)?.propsRef.current.id;

  const registerTab = React.useCallback((tab: TabInternal) => {
    setTabs((current) => sortItems([...current, tab]));
    return () =>
      setTabs((current) =>
        current.filter(
          (t) => t.propsRef.current.id !== tab.propsRef.current.id,
        ),
      );
  }, []);

  const selectPreviousTab = React.useCallback(() => {
    const prevItem = getItemByPosition(
      tabs,
      tabs.find((t) => t.propsRef.current.id === focusableTabId),
      'prev',
    );
    if (prevItem) {
      setActiveTabId(prevItem.propsRef.current.id);
      prevItem.ref.current?.focus();
    } else if (focusableTabId) {
      setActiveTabId(focusableTabId);
    }
  }, [focusableTabId, setActiveTabId, tabs]);

  const selectNextTab = React.useCallback(() => {
    const nextItem = getItemByPosition(
      tabs,
      tabs.find((t) => t.propsRef.current.id === focusableTabId),
      'next',
    );
    if (nextItem) {
      setActiveTabId(nextItem.propsRef.current.id);
      nextItem.ref.current?.focus();
    } else if (focusableTabId) {
      setActiveTabId(focusableTabId);
    }
  }, [focusableTabId, setActiveTabId, tabs]);

  const value = {
    id,
    dismissable,
    activeTabId,
    focusableTabId,
    setActiveTabId,
    selectPreviousTab,
    selectNextTab,
    registerTab,
  };

  return (
    // @ts-expect-error-auto TS(2322) FIXME: Type '{ id: string; dismissable: boolean | undefin... Remove this comment to see the full error message
    <TabbedInterfaceContext.Provider value={value}>
      {children}
    </TabbedInterfaceContext.Provider>
  );
};
