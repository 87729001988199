import type { WorkspaceMembersResponseT } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';

const PAGE_SIZE = 50;

export const useOrganizationMembersPaginated = (
  queryString: string | undefined,
  { includeNonMembers = false, skip = false, revalidateOnFocus = true } = {},
) => {
  const getKey = (pageIndex: number) => {
    if (skip) return undefined;

    const prefix = includeNonMembers
      ? '/workspaces/members/non-members'
      : '/workspaces/members';
    return `${prefix}${queryString}&offset=${encodeURIComponent(
      pageIndex * PAGE_SIZE,
    )}&limit=${encodeURIComponent(PAGE_SIZE)}`;
  };

  const {
    data,
    error,
    size: page,
    setSize: setPage,
    isValidating,
    mutate,
  } = useSWRInfinite<WorkspaceMembersResponseT>(
    getKey,
    async (url) => {
      const response = await core().get<WorkspaceMembersResponseT>(url);
      return response.data;
    },
    { shouldRetryOnError: false, revalidateOnFocus },
  );

  const members = useMemo(
    () => data?.flatMap(({ data }) => data) || [],
    [data],
  );

  const isLoadingInitialData = !data && !error;
  const isLoadingMoreMembers =
    isLoadingInitialData ||
    (page > 0 && data && typeof data[page - 1] === 'undefined');
  const isRefreshing = isValidating && data?.length === page;
  const hasReachedLastPage =
    data &&
    // @ts-expect-error-auto TS(2532) FIXME: Object is possibly 'undefined'.
    (data.length === 0 || data[data.length - 1].data?.length < PAGE_SIZE);

  const loadMoreMembers = useCallback(() => {
    if (isLoadingMoreMembers || hasReachedLastPage) return;
    setPage(page + 1);
  }, [hasReachedLastPage, isLoadingMoreMembers, setPage, page]);

  return {
    members,
    page,
    setPage,
    isValidating,
    isLoading: isLoadingInitialData || isLoadingMoreMembers || isRefreshing,
    loadMoreMembers,
    isLoadingInitialMembersData: isLoadingInitialData,
    error,
    mutate,
  };
};
