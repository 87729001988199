import type { QuestionWithSlide as EditorQuestionWithSlide } from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import update, { type CustomCommands, type Spec } from 'immutability-helper';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { createEditorSlideCompatibilityLayer } from './create-editor-slide-compatibility-layer';

export const updateEditorSlideProxy = <
  T extends Question | EditorQuestionWithSlide,
  R = T | undefined,
>(
  source: R,
  spec: Spec<T, CustomCommands<T>>,
): R => {
  if (!source) return undefined as R;

  let questionData: Question = source as unknown as T;

  if (isProxyInstance(source)) {
    questionData = (source as unknown as { __target: Question }).__target;
  }

  return createEditorSlideCompatibilityLayer(
    update(questionData, spec as any),
  ) as R;
};
