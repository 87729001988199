import { useState, forwardRef, useImperativeHandle } from 'react';
import { useEditor } from '@tiptap/react';
import { type Editor } from '@tiptap/core';
import CharacterCountExtension from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import Document from '@tiptap/extension-document';
import TextExtension from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import Mention from '@tiptap/extension-mention';
import { stringToCharacterCount, ErrorMessage } from '@mentimeter/ragnar-ui';
import { useRagnar } from '@mentimeter/ragnar-react';
import { safeJSONParse } from '../util/safeJSONParse';
import {
  createSuggestion,
  type ReactRendererComponentType,
} from '../components/suggestion';
import { RichTextEditor } from './RichTextEditor';

export interface EditorHandler {
  getEditor: () => Editor | undefined;
  getLength: () => number;
}

interface CommentTextEditorProps {
  autofocus: boolean;
  onFocus: () => void;
  onBlur: () => void;
  value: string;
  placeholder: string;
  showExpanded: boolean;
  onKeyboardSubmit: (editor: Editor) => void;
  onUpdate: () => void;
  onInitiateMention: () => void;
  maxLength: number;
  minHeight: number | undefined;
}

export const CommentTextEditor = forwardRef<
  EditorHandler,
  CommentTextEditorProps
>(
  (
    {
      autofocus,
      onFocus,
      onBlur,
      value,
      placeholder,
      showExpanded,
      onKeyboardSubmit,
      onUpdate,
      onInitiateMention,
      maxLength,
      minHeight,
    },
    ref,
  ) => {
    const { theme } = useRagnar();

    const [showPopover, setShowPopover] = useState(false);
    const [suggestionContent, setSuggestionContent] =
      useState<ReactRendererComponentType>();
    const [position, setPosition] = useState<DOMRect | null>(null);

    const content = safeJSONParse(value) ?? '';

    const extensions = [
      Document.extend({
        addKeyboardShortcuts() {
          return {
            'Mod-Enter': () => {
              onKeyboardSubmit(this.editor);
              return true;
            },
          };
        },
      }),
      Placeholder.configure({ placeholder }),
      TextExtension,
      Paragraph,
      CharacterCountExtension.extend({
        // This code is copied from the extension's source code and modified
        // to use stringToCharacterCount(text) instead of text.length
        onBeforeCreate() {
          this.storage.characters = (options) => {
            const node = options?.node || this.editor.state.doc;
            const mode = options?.mode || this.options.mode;

            if (mode === 'textSize') {
              const text = node.textBetween(
                0,
                node.content.size,
                undefined,
                ' ',
              );

              return stringToCharacterCount(text);
            }

            return node.nodeSize;
          };

          this.storage.words = (options) => {
            const node = options?.node || this.editor.state.doc;
            const text = node.textBetween(0, node.content.size, ' ', ' ');
            const words = text.split(' ').filter((word) => word !== '');

            return words.length;
          };
        },
      }).configure({
        limit: maxLength,
      }),
      Mention.configure({
        HTMLAttributes: {
          style: `color: ${theme.colors.brand}`,
        },
        suggestion: createSuggestion(
          (
            component: ReactRendererComponentType,
            clientRect: DOMRect | null,
          ) => {
            setPosition(clientRect);
            setSuggestionContent(component);
            setShowPopover(true);
          },
          () => {
            setShowPopover(false);
          },
          onInitiateMention,
        ),
      }),
    ];

    const editor = useEditor(
      {
        autofocus,
        editable: true,
        editorProps: {
          attributes: {
            role: 'textbox',
            'aria-multiline': 'true',
            'aria-label': placeholder,
            class: 'tiptap-editor',
            'data-testid': 'comment-editor',
          },
        },
        extensions,
        content,
        immediatelyRender: false,
        onFocus,
        onBlur,
        onUpdate,
      },
      [onKeyboardSubmit],
    );

    useImperativeHandle(ref, () => ({
      getEditor: () => editor ?? undefined,
      getLength: () =>
        editor?.extensionStorage.characterCount.characters() ?? 0,
    }));

    if (!editor) {
      return null;
    }

    const textContent = editor.getText();
    const charCount = editor.extensionStorage.characterCount.characters();
    const hasMaxCharCount = charCount >= maxLength;

    return (
      <>
        <RichTextEditor
          showExpanded={showExpanded}
          editor={editor}
          showPopover={showPopover}
          position={position}
          suggestionContent={suggestionContent}
          textContent={textContent}
          minHeight={minHeight}
        />
        {showExpanded && hasMaxCharCount && (
          <ErrorMessage mt="space2">
            You have reached the maximum length
          </ErrorMessage>
        )}
      </>
    );
  },
);
