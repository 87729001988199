import { useEffect, useRef, type MouseEventHandler } from 'react';
import { Box, RATIO_LANDSCAPE } from '@mentimeter/ragnar-ui';

const useResizeObserver = (
  ref: React.RefObject<HTMLElement | null>,
  callback: ResizeObserverCallback,
) => {
  useEffect(() => {
    let observer: ResizeObserver;
    if (window.ResizeObserver && ref.current) {
      observer = new ResizeObserver(callback);
      observer.observe(ref.current);
    }
    return () => {
      observer?.disconnect();
    };
  }, [ref, callback]);
};

interface AspectRatioContainerProps {
  onClick?: MouseEventHandler | undefined;
  children: React.ReactNode;
}

export const AspectRatioContainer = ({
  onClick,
  children,
}: AspectRatioContainerProps) => {
  const outerContainerRef = useRef<HTMLDivElement | null>(null);
  const innerContainerRef = useRef<HTMLDivElement | null>(null);

  const supportsResizeObserver = 'ResizeObserver' in window;

  const updateOuterContainerHeight = (entries: ResizeObserverEntry[]) => {
    window.requestAnimationFrame(() => {
      if (entries[0]) {
        const element = entries[0].target as HTMLElement;
        element.style.height = `${element.offsetWidth * (9 / 16)}px`;
      }
    });
  };
  const updateInnerContainerWidth = (entries: ResizeObserverEntry[]) => {
    window.requestAnimationFrame(() => {
      if (entries[0]) {
        const element = entries[0].target as HTMLElement;
        element.style.width = `${element.offsetHeight * (16 / 9) - 2}px`;
      }
    });
  };

  useResizeObserver(outerContainerRef, updateOuterContainerHeight);
  useResizeObserver(innerContainerRef, updateInnerContainerWidth);

  return (
    <>
      {supportsResizeObserver && (
        <Box
          ref={outerContainerRef}
          width="100%"
          flex="0 1 auto"
          alignItems="center"
        >
          <Box
            ref={innerContainerRef}
            flex="1 1 auto"
            alignItems="center"
            onClick={onClick}
          >
            {children}
          </Box>
        </Box>
      )}
      {!supportsResizeObserver && (
        <Box
          position="relative"
          display="block"
          width="100%"
          mb={2}
          pb={RATIO_LANDSCAPE}
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            alignItems="center"
            borderRadius={2}
            overflow="hidden"
            onClick={onClick}
          >
            {children}
          </Box>
        </Box>
      )}
    </>
  );
};
