import * as React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { QfaIndicator as QfaIndicatorIcon } from '@mentimeter/ragnar-visuals';
import { useQfa, useQuestion, useSeries } from '../../../data-mediation';
import { Indication } from '../Indication';
import { IndicationNumber } from '../IndicationNumbers';
import { IndicationDot } from './indication-dot';

const IndicationContent = ({ pendingCount }: { pendingCount: number }) => {
  return (
    <>
      <IndicationDot />
      <Indication>
        <IndicationNumber number={pendingCount} />
        <QfaIndicatorIcon size={3} color="text" />
      </Indication>
    </>
  );
};

export const QfaIndicator = () => {
  const { type } = useQuestion();
  const { qfa_active } = useSeries();
  const { ids } = useQfa();
  const pendingCount = ids.unanswered.total;
  const qfaActive = qfa_active || type === 'qfa';

  if (!qfaActive) return null;

  return (
    <Box alignItems="center" data-testid="qfa-indicator">
      <IndicationContent pendingCount={pendingCount} />
    </Box>
  );
};
