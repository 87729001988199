// This file is automatically generated. DO NOT EDIT.

import { type Comment } from '../types/response/Comment';
import { fromSnakeKeys } from '@api/internal';

export interface GetCommentsResponse {
  /** List of comments */
  data: Array<Comment>;
}

export async function getCommentsResponse(
  response: Response,
): Promise<GetCommentsResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetCommentsResponse;
}
