import { Box } from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';

export function PanelsLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Box
      as="main"
      flex="1 1 auto"
      flexDirection="row"
      width="100%"
      height="100%"
    >
      {children}
    </Box>
  );
}
